import React, { useState, useEffect } from "react";
import { NavLink as RRNavlink } from 'react-router-dom';
import {
    Row, Col, Card, CardHeader, CardBody, CardText,
    Nav, NavLink, NavItem, TabContent, TabPane, Collapse, Badge
} from "reactstrap";
import AwarenessPieChart from '../awareness/charts/PieChart';
import ParticipationPieChart from '../participation/charts/PieChart';

const ReportTabs = (props) => {

    const [activePolicy, setActivePolicy] = useState();
    const [activeReport, setActiveReport] = useState();

    // use props to configure the active policy and report
    useEffect(() => {

        let targetPolicy = props.selectedPolicy || "";
        let targetReport = props.selectedScenario || "";

        if (props.statistics && props.statistics.length > 0) {

            // Request to display a specific report
            if (props.selectedScenario) {
                // find the associated policy
                targetPolicy = props.statistics.find(stat => stat._id === props.selectedScenario).scenario.policy.policyId;
            }
            // Request to display a specific policy
            else if (props.selectedPolicy) {
                // find the first report for this policy
                targetReport = props.statistics.find(stat => stat.scenario.policy.policyId === props.selectedPolicy)._id;

                // Note: it should not be possible for a user to request a specific policy unless the policy has at least one associated statistic
                //       However to future-proof the code, conditionally check for the case of no stat found, and display an appropriate message in the report pane
            }
            else {
                // if no policy or report is requested then default to the first statistics
                targetReport = props.statistics[0]._id;
                targetPolicy = props.statistics[0].scenario.policy.policyId;
            }

            setActivePolicy(targetPolicy);
            setActiveReport(targetReport);
        }
    },[props.selectedPolicy, props.selectedScenario, props.statistics]);

    const togglePolicy = (selectedPolicy) => {

        if (selectedPolicy === activePolicy) {
            //if the active policy is selected, deactivate it
            setActivePolicy(0);
        }
        else {

            let defaultReport = props.statistics.find(stat => stat.scenario.policy.policyId === selectedPolicy);

            if (defaultReport) {
                setActiveReport(defaultReport._id);
            }
            else {
                setActiveReport("")
            }

            setActivePolicy(selectedPolicy);
        }
    }

    return (
        <>
            <Row>
                <Col lg="3">
                    <Card className="bg-container-navy mb-4">
                        <CardHeader className="section-header">Security Checks</CardHeader>
                        <CardBody>
                            <Nav pills role="tablist">
                                {
                                    props.policies.map((policy, index) => {

                                        let policyScenarios = props.statistics.filter(stat => { return stat.scenario.policy.policyId === policy._id });

                                        return (
                                            <NavItem key={index} id="policy-list-item">
                                                <NavLink
                                                    className={`mb-2 ${activePolicy === policy._id ? "active" : ""}`}
                                                    href="#"
                                                    onClick={() => togglePolicy(policy._id)}>
                                                    {policy.title}
                                                    {
                                                        policyScenarios.length > 0 &&
                                                        <Badge pill color="primary" className="ml-2">{policyScenarios.length}</Badge>
                                                    }
                                                </NavLink>
                                                <Collapse isOpen={activePolicy === policy._id}>
                                                    <Nav role="tablist">
                                                        {
                                                            policyScenarios.map((stat) => {
                                                                let formattedDate = new Date(stat.sentDate).toLocaleDateString('en-us', { day: 'numeric', month: 'short', year: 'numeric' });
                                                                return (
                                                                    <NavItem key={stat._id} id="scenario-list-item">
                                                                        <NavLink
                                                                            className={`mb-2 ${activeReport === stat._id ? "highlightReport" : ""}`}
                                                                            style={{ fontSize: '0.85rem', color: 'white' }}
                                                                            href="#"
                                                                            onClick={(e) => {
                                                                                e.preventDefault();
                                                                                setActiveReport(stat._id);
                                                                            }}
                                                                        >
                                                                            {formattedDate}
                                                                        </NavLink>
                                                                    </NavItem>
                                                                )
                                                            })
                                                        }
                                                    </Nav>
                                                </Collapse>
                                            </NavItem>
                                        )
                                    })
                                }
                            </Nav>

                        </CardBody>
                    </Card>
                </Col>
                <Col lg="9">
                    <Card className="bg-container-navy">
                        <CardHeader className="section-header">Scenario Results</CardHeader>
                        {
                            activeReport === "" &&
                            <CardBody>
                                <CardText>There are no reports yet for this policy.</CardText>
                            </CardBody>
                        }

                        {
                            activeReport !== "" &&

                            <CardBody>
                                <TabContent id="myTabContent" activeTab={activeReport}>
                                    {
                                        props.statistics.map(stat => {

                                            let formattedDate = new Date(stat.sentDate).toLocaleDateString('en-us', { day: 'numeric', month: 'short', year: 'numeric' });

                                            const questionParagraphs = stat.scenario.question.text.split('\n');

                                            return (
                                                <TabPane tabId={stat._id} key={stat._id} role="tabpanel">

                                                    <div id="flex-container" className="flex-md-nowrap">
                                                        <Nav vertical id="scenarioTab-nav">
                                                            <NavItem>
                                                                <NavLink tag={RRNavlink} to={{ pathname: '/dashboard/policy', state: { selectedPolicy: stat.scenario.policy.policyId } }} className="p-0 text-white">
                                                                    <i className="fa fa-book" id="navIcon"></i> {props.policies.find(policy => policy._id === stat.scenario.policy.policyId).title}
                                                                </NavLink>
                                                            </NavItem>
                                                            <NavItem><i className="fa fa-group" id="navIcon"></i> {stat.numSent} Participant{stat.numSent === 1 ? '' : 's'}</NavItem>
                                                            <NavItem><i className="fa fa-calendar" id="navIcon"></i> {formattedDate}</NavItem>
                                                        </Nav>
                                                        <div id="scenario-exercise" className="mt-3">
                                                            <div id="scenario-question">
                                                                <blockquote className="content-text mb-0">
                                                                    {questionParagraphs}
                                                                </blockquote>
                                                            </div>
                                                            <div id="scenario-answers" className="mt-5 ml-3">
                                                                {
                                                                    stat.scenario.question.answers.map((answer, i) => {
                                                                        return (
                                                                            <CardText key={i} className="content-text">
                                                                                {i + 1}. {answer.text}
                                                                                {answer.checked ?
                                                                                    <i className="fa fa-check-square-o ml-2 text-primary" id="navIcon"></i> :
                                                                                    <i className="fa fa-window-close-o ml-2 text-danger" id="navIcon"></i>
                                                                                }
                                                                            </CardText>
                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Nav vertical id="scenarioTab-nav">
                                                        <NavItem><i className="fa fa-pie-chart" id="navIcon"></i> Team Results:</NavItem>
                                                    </Nav>

                                                    {
                                                        (stat.numReply === 0) &&

                                                        <div className="text-center content-text">
                                                            <p className="content-text">No results yet. Try again later.</p>
                                                        </div>
                                                    }

                                                    {
                                                        (stat.numReply > 0) &&

                                                        <div id="flex-container" className="justify-content-around">
                                                            <div id="flex-item-graph">
                                                                <AwarenessPieChart
                                                                    correctFirstTime={stat.numFirstCorrect}
                                                                    correctSecondTime={stat.numSecondCorrect}
                                                                    incorrect={stat.numIncorrect}
                                                                />
                                                            </div>
                                                            <div id="flex-item-graph">
                                                                <ParticipationPieChart
                                                                    responding={stat.numReply}
                                                                    noResponse={stat.numSent - stat.numReply}
                                                                    notVerified={stat.numUnverified}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </TabPane>
                                            )
                                        })
                                    }
                                </TabContent>
                            </CardBody>
                        }
                    </Card>
                </Col>
            </Row>
        </>
    );
}

export default ReportTabs;