import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import SiteNavbar from './Navbar';
import Footer from '../common/Footer';
import './landing.css';

const Error = (props) => {

    let errMsg = "Our team has been notified and will resolve this asap.";

    console.log("Props:", props.error)

    // NOTE (15-09-2023): single source of truth for the error state should always be the application state (i.e. redux store)
    if (props.location.state) {

        // BUG: temporary workaround to #285
        
        if (props.location.state.msg && (typeof props.location.state.msg === 'string')) {
            errMsg = props.location.state.msg;
        }
    }
    // else if(props.error.msg.msg) {
    //     errMsg = props.error.msg.msg;
    // }

    return (
        <div className="top-container">
            <nav><SiteNavbar /></nav>
            <img src='/site-img.jpg' alt='site background' height="1080px" width="1920px" style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
            <div className="bg-img-overlay"></div>
            <div className="content-container">
                <Container fluid className="ml-md-4 light">
                    <TransitionGroup>
                        <CSSTransition key={'ScenarioError'} appear={true} timeout={1000} classNames="fade">
                            <div>
                                <Row>
                                    <Col md="12" lg="10" xl="8">
                                        <h1 className="text-danger">HTTP Error</h1>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md="12" lg="10" xl="8">
                                        <p className="lead">{errMsg}</p>
                                    </Col>
                                </Row>
                            </div>
                        </CSSTransition>
                    </TransitionGroup>
                </Container>
            </div>
            <Footer className="mt-3" />
        </div>
    )
}

Error.propTypes = {
    error: PropTypes.object,
};

const mapStateToProps = state => ({
    error: state.error
});

export default withRouter(connect(mapStateToProps)(Error));
//export default Error;