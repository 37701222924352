import React from 'react';
import axios from 'axios';
import { Spinner, Form, Input, Button, Alert, FormGroup } from 'reactstrap'

class AccountConfirmation extends React.Component {

    state = {
        isConfirming: true,
        isResending: false,
        confirmed: false,
        expired: false,
        error: null,
        resendSuccess: false,
        resendFail: false,
        msg: null,
        email: ''
    }

    componentDidMount() {

        // extract emailed token from URL
        const { confirmationToken } = this.props.match.params

        const config = {
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": confirmationToken
            }
        }
        // API call to verify user confirmation
        axios.get('/api/accounts/confirmation/new', config)
            .then(response => {
                this.setState({ isConfirming: false, confirmed: true });
            })
            .catch(err => {
                this.setState({ isConfirming: false, error: err.response.status });
            });
    }

    // switch to sign-in page after successful confirmation
    handleProceed = e => {
        e.preventDefault();

        this.props.history.push('/start/signin');
    }

    // if confirmation fails, provide option to resend a fresh confirmation link
    handleResend = e => {
        e.preventDefault();

        this.setState({ isResending: true, resendSuccess: false, resendFail: false, msg: '' })

        const config = {
            headers: {
                "Content-Type": "application/json"
            }
        }

        const body = JSON.stringify( {email: this.state.email} );

        // API call to request new confirmation link be emailed to the user
        axios.post('/api/accounts/confirmation/new/resend', body, config)
            .then(response => {
                this.setState({ isResending: false, resendSuccess: true, msg: response.data.msg, email: '' });
            })
            .catch(err => {
                this.setState({ isResending: false, resendFail: true, msg: err.response.data.msg });
            });
    }

    // use controlled input to save entered email address to component state
    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };


    render() {
        const { isConfirming, confirmed, error } = this.state;

        if (isConfirming) {
            return <Spinner color="primary" />
        }

        else if(error) {
            return (
                <div className="form-container">                    
                    {this.state.isResending ? <Alert color="secondary">Please wait a moment...</Alert> : null}                    
                    {this.state.resendSuccess ? <Alert color="success">{this.state.msg}</Alert> : null}
                    {this.state.resendFail ? <Alert color="danger">{this.state.msg}</Alert> : null}
                    <Form className="form p-3" onSubmit={this.handleResend} >
                        <h3>Confirmation Failed</h3>
                        <p className="text-dark">Your confirmation link may have expired.</p>
                        <p className="text-dark pb-4">No problem. We will resend you a new one.</p>                     
                        <FormGroup>
                            <Input type="email" name="email" placeholder='enter email address' bsSize='sm' onChange={this.onChange} value={this.state.email} autoComplete="username" required />
                        </FormGroup>
                        <Button className="form-button">RESEND</Button>
                    </Form>
                </div>
            )
        } 
        else if(confirmed) {
            return (
                <div className="form-container">
                    <Form className="form p-3" onSubmit={this.handleProceed} >
                        <h3>Account Confirmed</h3>
                        <p className="text-dark pb-4">Click the button below to log into your new account.</p>                     
                        <Button className="form-button">CONTINUE</Button>
                    </Form>
                </div>
            )
        }                
    }
}

export default AccountConfirmation;