import axios from 'axios';
import {
    ACCOUNT_DELETE_REQUEST_FAIL,
    ACCOUNT_DELETE_REQUEST_SUCCESS,
    CLEAR_MSG,
    DATA_LOADING,
    LOGIN_FAIL,
    LOGIN_SUCCESS,    
    REGISTER_FAIL, 
    REGISTER_SUCCESS,
    REQUEST_RESET_FAIL, 
    REQUEST_RESET_SUCCESS,
    RESET_FAIL,
    RESET_SUCCESS,
    USER_LOADED,
    USER_LOADING_FAILED
    
} from "./types";
import { returnErrors } from './errorActions';

// Action: verifyUser
// Desc: verify the users token
export const verifyUser = () => (dispatch, getState) => {

    // call API to retrieve account data
    axios.get('/api/accounts/user', tokenConfig(getState))
    .then(response => {
        dispatch({
            type: USER_LOADED,
            payload: response.data
        });
    })
    .catch(err => {

        if(err.response) {

            if (err.response.data.msg === 'Token expired') {
                dispatch( returnErrors(err.response.data, err.response.status, 'TOKEN_EXPIRED'));
            }
            else {
                dispatch( returnErrors(err.response.data, err.response.status, USER_LOADING_FAILED));
            }            
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, USER_LOADING_FAILED));
        }
       
        dispatch( {
            type: USER_LOADING_FAILED
        });
    });
}



// Action:  register
// Desc:    register a new user account
export const register = ( { name, email, password, organization}, history) => dispatch => {

    // re-using this flag for user registration process
    dispatch( {type: DATA_LOADING });
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify( {name, email, password, organization} );

    // call API to register a new user account
    axios.post('/api/accounts', body, config)
    .then(res => dispatch( {
        type: REGISTER_SUCCESS,
        payload: res.data
    }))
    .catch(err => {

        if(err.response) {
            dispatch( returnErrors(err.response.data, err.response.status, REGISTER_FAIL));
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, REGISTER_FAIL));
        }
        
        dispatch( {
            type: REGISTER_FAIL,            
        })
    })
}

// Action:  login
// Desc:    verify user credentials
export const login = ( { email, password}, history) => dispatch => {
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify( {email, password} );

    axios.post('/api/accounts/login', body, config)
    .then(res => {
        dispatch( {
        type: LOGIN_SUCCESS,
        payload: res.data
    })})
    .catch(err => {
        if(err.response) {
            dispatch( returnErrors(err.response.data, err.response.status, LOGIN_FAIL));
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, LOGIN_FAIL));
        }
        
        dispatch( {
            type: LOGIN_FAIL,            
        });
    })
}

// Action:  resetRequest
// Desc:    process a request to reset user password
export const requestReset = ( {email}, history) => dispatch => {

    // re-using this flag for user pw reset request process
    dispatch( {type: DATA_LOADING });
    
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify( {email} );

    axios.post('/api/accounts/password', body, config)
    .then(res => dispatch( {
        type: REQUEST_RESET_SUCCESS,
        payload: res.data
    }))
    .catch(err => {
        if(err.response) {
            dispatch( returnErrors(err.response.data, err.response.status, REQUEST_RESET_FAIL));
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, REQUEST_RESET_FAIL));
        }
                
        dispatch( {
            type: REQUEST_RESET_FAIL,            
        });
    })
}

// Action:  pwReset
// Desc:    reset user password
export const pwReset = ( {password}, token) => dispatch => {

     // re-using this flag for user pw reset process
     dispatch( {type: DATA_LOADING });
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token
        }
    }
    
    const body =  JSON.stringify( {password});

    axios.put('/api/accounts/password', body, config)
    .then(res => dispatch( {
        type: RESET_SUCCESS,
        payload: res.data
    }))
    .catch(err => {
        if(err.response) {
            if (err.response.data.msg === 'Token expired') {
                dispatch( returnErrors(err.response.data, err.response.status, 'TOKEN_EXPIRED'));
            }
            else {
                dispatch( returnErrors(err.response.data, err.response.status,  RESET_FAIL));
            }
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, RESET_FAIL));
        }
        
        dispatch( {
            type: RESET_FAIL,            
        });
    })
}

// Action:  deleteAccount
// Desc:    submit users request to delete the account
export const deleteAccount = () => (dispatch, getState) => {
   
    // call API to request deleting the account
    axios.delete('/api/accounts',tokenConfig(getState))
    .then(res => {
        dispatch({type: ACCOUNT_DELETE_REQUEST_SUCCESS });
    })
    .catch(err => {
        if(err.response) {
            if (err.response.data.msg === 'Token expired') {
                dispatch( returnErrors(err.response.data, err.response.status, 'TOKEN_EXPIRED'));
            }
            else {
                dispatch( returnErrors(err.response.data, err.response.status, ACCOUNT_DELETE_REQUEST_FAIL));
            }
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, ACCOUNT_DELETE_REQUEST_FAIL));
        }
    })
}


export const clearMsg = () => {
    return {
        type: CLEAR_MSG
    }
}

// Function:  tokenConfig
// Desc:    retrieve token from state and add to config header
export const tokenConfig = getState => {
    const token = getState().auth.token;

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    if(token) {
        config.headers['x-auth-token'] = token;
    }

    return config;
}