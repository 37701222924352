import React from 'react';
import axios from 'axios';
import { Spinner, Form, Button } from 'reactstrap'

class DeleteConfirmation extends React.Component {

    state = {
        isConfirming: true,
        confirmed: false,
        error: null,
        msg: null
    }

    componentDidMount() {

        // extract emailed token from URL
        const { confirmationToken } = this.props.match.params

        const config = {
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": confirmationToken
            }
        }
        // API call to verify user confirmation
        axios.get('/api/accounts/confirmation/delete', config)
            .then(response => {
                this.setState({ isConfirming: false, confirmed: true });
            })
            .catch(err => {
                this.setState({ isConfirming: false, error: err.response.status });
            });
    }

    // switch to home page 
    handleProceed = e => {
        e.preventDefault();

        this.props.history.push('/');
    }




    render() {
        const { isConfirming, confirmed, error } = this.state;

        if (isConfirming) {
            return <Spinner color="primary" />
        }

        else if (error) {
            return (
                <div className="form-container">
                    <Form className="form p-3">
                        <h3>Delete Failed</h3>
                        <p className="text-dark">Your confirmation link may have expired.</p>
                        <p className="text-dark pb-4">Our team will resolve this issue ASAP.</p>
                    </Form>
                </div>
            )
        }
        else if (confirmed) {
            return (
                <div className="form-container">
                    <Form className="form p-3" onSubmit={this.handleProceed} >
                        <h3>Account Deleted</h3>
                        <p className="text-dark pb-4">Thank you for your interest in Inside Defense.</p>
                        <Button className="form-button">Close</Button>
                    </Form>
                </div>
            )
        }
    }
}

export default DeleteConfirmation;