import React from 'react';
import { Col, Button, Form, FormGroup, Input, FormText } from 'reactstrap';
import Axios from 'axios';

class Contact extends React.Component {

    state = {
        name: '',
        email: '',
        subject: '',
        message: '',
        buttonText: 'SEND',
        buttonColor: 'primary',
        buttonDisabled: false
    };

    formSubmit = (e) => {
        e.preventDefault();

        const data = {
            name: this.state.name,
            email: this.state.email,
            subject: this.state.subject,
            message: this.state.message
        }

        this.setState({ buttonText: 'Sending...', buttonColor: 'info' })

        // call API to add defenders to the account
        Axios.post('/api/contacts/message', data)
            .then(res => {
                this.setState({
                    name: '',
                    email: '',
                    subject: '',
                    message: '',
                    buttonText: 'Message Sent',
                    buttonColor: 'success',
                    buttonDisabled: true
                })
            })
            .catch(err => {
                this.setState({
                    buttonText: 'Error',
                    buttonColor: 'danger',
                    buttonDisabled: true
                })
            })
    }

    render() {
        return (
            <div className="form-container">
                <Form className="content-text form contact-form" onSubmit={e => { this.formSubmit(e) }}>
                    <h3>Contact Us</h3>
                    <FormGroup row>
                        <Col md="6">
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="name"
                                    bsSize='sm'
                                    value={this.state.name}
                                    onChange={(e) => { this.setState({ name: e.target.value }) }}
                                    maxLength='50'
                                />
                            </FormGroup>
                        </Col>
                        <Col md="6">
                            <FormGroup>
                                <Input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="email"
                                    bsSize='sm'
                                    value={this.state.email}
                                    onChange={(e) => { this.setState({ email: e.target.value }) }}
                                    required
                                    maxLength='50'
                                />
                            </FormGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <FormGroup>
                                <Input
                                    type="text"
                                    name="subject"
                                    id="subject"
                                    placeholder="subject"
                                    bsSize='sm'
                                    value={this.state.subject}
                                    onChange={(e) => { this.setState({ subject: e.target.value }) }}
                                    maxLength='100'
                                />
                            </FormGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup row>
                        <Col>
                            <FormGroup>
                                <Input
                                    type="textarea"
                                    name="message"
                                    id="message"
                                    placeholder="message"
                                    bsSize='sm'
                                    value={this.state.message}
                                    onChange={(e) => { this.setState({ message: e.target.value }) }}
                                    required
                                    maxLength='500'
                                />
                            </FormGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup row className="text-center">
                        <Col>
                            <Button className="site-button form-button" disabled={this.state.buttonDisabled}>{this.state.buttonText}</Button>
                            <FormText className="form-text">
                                {this.state.buttonText === 'SEND' && 'Rest assured your data will remain private and will not be shared with anyone.'}
                                {this.state.buttonText === 'Message Sent' && 'Thank you. We will reply to your message as soon as possible.'}
                                {this.state.buttonText === 'Error' && 'Looks like there is a problem with the form. We will solve this asap. Please try again later'}
                            </FormText>
                        </Col>
                    </FormGroup>
                </Form>
            </div>
        );
    }
}

export default Contact;
