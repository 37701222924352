import React from 'react';
import { Container, Card, CardBody, Row, Col } from 'reactstrap';
import Header from '../../common/Header';

export const PageNotFound = (props) => {
    return (
        <React.Fragment>
            <Header center title="Error" icon="fa fa-warning" />

            <Container fluid className="text-white my-4">
                <Row>
                    <Col lg={{ size: 6, offset: 3 }}>
                        <Card className="bg-container-navy text-center">
                            <CardBody>
                                <div>
                                    <h5>Page Not Found</h5>
                                    <p>Please use the links provided above to navigate</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}