import axios from 'axios';
import {
    DEFENDER_AUTHENTICATED,
    DEFENDER_AUTHENTICATING,
    DEFENDER_AUTHENTICATION_FAILED,
    DEFENDER_LOADING,
    DEFENDER_LOADED,
    DEFENDER_LOADING_FAILED,
    PROFILE_DELETE_REQUEST_FAIL,
    PROFILE_DELETE_REQUEST_SUCCESS,
} from './types';
import { returnErrors } from '../actions/errorActions';


// Desc:    Send AuthN token for verification and retrieve an AuthZ token 
export const authenticateDefender = (defenderToken) => dispatch => {

    // set Defender Loading state
    dispatch({ type: DEFENDER_AUTHENTICATING });

    const config = {
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": defenderToken
        }
    }

    // call API to verify defender identify and retrieve Authz token
    axios.get('/api/defenders/authn', config)
        .then(res => {
            dispatch({ type: DEFENDER_AUTHENTICATED, payload: res.data });
        })
        .catch(error => {

            if (error.response) {
                // The server responded with a status code out of the range of 2xx
                console.log("DEBUG: ", error.response.data);

                dispatch(returnErrors(error.response.data.msg, error.response.status));
            }
            else {
                // The server did not respond 
                if (error.request) { console.log("DEBUG: ", error.request); }

                // The client could not send the request  
                else { console.log("DEBUG: ", error.message); }

                dispatch(returnErrors("Our team has been notified and will resolve the error asap.", 500));
            }

            dispatch({ type: DEFENDER_AUTHENTICATION_FAILED })
        });
}

// Action:  loadDefender
// Desc:    Send Authz token for verification and retrieve defender data
export const loadDefender = (defenderToken) => dispatch => {

    // set Defender Loading state
    dispatch({ type: DEFENDER_LOADING });

    const config = {
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": defenderToken
        }
    }

    // call API to retrieve defender data
    axios.get('/api/defenders', config)
        .then(res => {
            dispatch({ type: DEFENDER_LOADED, payload: res.data });
        })
        .catch(error => {

            if (error.response) {
                // The server responded with a status code out of the range of 2xx
                console.log("DEBUG: ", error.response.data);

                dispatch(returnErrors(error.response.data.msg, error.response.status));
            }
            else {
                // The server did not respond 
                if (error.request) { console.log("DEBUG: ", error.request); }

                // The client could not send the request  
                else { console.log("DEBUG: ", error.message); }

                dispatch(returnErrors("Our team has been notified and will resolve the error asap.", 500));
            }

            dispatch({ type: DEFENDER_LOADING_FAILED })
        });
}


// Action:  deleteProfile
// Desc:    submit users request to delete the account
export const deleteProfile = (defenderToken) => dispatch => {

    const config = {
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": defenderToken
        }
    }

    // call API to request deleting the account
    axios.delete('/api/defenders', config)
        .then(res => {
            dispatch({ type: PROFILE_DELETE_REQUEST_SUCCESS });
        })
        .catch(err => {
            if (err.response) {
                if (err.response.data.msg === 'Token expired') {
                    dispatch(returnErrors(err.response.data, err.response.status, 'TOKEN_EXPIRED'));
                }
                else {
                    dispatch(returnErrors(err.response.data, err.response.status, PROFILE_DELETE_REQUEST_FAIL));
                }
            }
            else if (err.request) { //no response received
                dispatch(returnErrors({ msg: "Internal Error. Please try again later." }, 500, PROFILE_DELETE_REQUEST_FAIL));
            }
        })
}