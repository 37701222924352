import React from 'react';
import { Card, CardHeader, CardBody, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';

export const HomeCard = (props) => {

    return (
        <Card className="bg-container-navy text-center">
            <CardHeader className="d-flex align-items-center justify-content-center">
                <i className={props.data.icon} aria-hidden="true"></i>
                <span style={{ fontSize: '0.9rem' }}>{props.data.header}</span>
            </CardHeader>
            <CardBody>                
                <Link to={{ pathname: props.data.link }}>
                    <CardText tag="h1"><strong>{props.data.value}{props.data.valueText}</strong></CardText>
                </Link>
                <CardText className="smallText mb-1 text-secondary"><strong>{props.data.text}</strong></CardText>
            </CardBody>
        </Card>
    )
}