import React from 'react';
import './common.css';

const Footer = (props) => {
  
  return ( 
    <footer className={props.dark ? "common-footer bg-opaque-dark" : "common-footer"}>      
         <p>&copy; 2022 insidedefense.net. All Rights Reserved. <a href="/privacy" target="_blank" rel="noopener noreferrer">Privacy.</a></p>
    </footer>
  );
}

export default Footer