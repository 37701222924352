import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GaugeChart from './GaugeChart';
import { Row, Col, Card, CardHeader, CardBody, CardText, CardTitle } from 'reactstrap';


const Result = (props) => {

    const { practice } = props.scenario;
    const { awarenessRate, responseRate } = props;

    return (
        <React.Fragment>
            <Row className="mb-3">
                <Col lg="6" className="d-flex flex-column align-items-center">
                    <Card className="bg-transparent mb-2">
                        <CardHeader className="py-0">
                            <CardTitle id={props.correct ? "" : "wrong-answer"} tag="h6">{props.correct ? "CORRECT" : "INCORRECT"}</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <CardText>{props.correct ? "Well done," : "Unfortunately"} the answer you selected <em>is {props.correct ? "" : "not"}</em> the recommended practice for this scenaro.</CardText>
                            <CardText><blockquote className="mb-0">
                                {practice}
                            </blockquote></CardText>
                            <CardText>Adopting this as a regular practice can help defend you and your team against common cyber attacks.</CardText>
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="6" className="d-flex flex-column align-items-center">
                    <Card className="bg-container-navy mb-2">
                        <CardHeader>
                            <CardTitle className="text-white text-center">DEFENSIVE STATS</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <GaugeChart a={awarenessRate} r={responseRate} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    )
}


Result.propTypes = {
    scenario: PropTypes.object,
}

const mapStateToProps = state => ({
    scenario: state.scenario.scenario,
    statsAreLoaded: state.scenario.statsAreLoaded,
    awarenessRate: state.scenario.awarenessRate,
    responseRate: state.scenario.responseRate
})

export default connect(mapStateToProps, null)(Result);