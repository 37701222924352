import React from "react";

import {
    Row,
    Col,
    Card,
    NavItem,
    NavLink,
    Nav,
    TabContent,
    TabPane,
    CardHeader,
    CardBody,
    ListGroup,
    ListGroupItem,
} from "reactstrap";

const PolicyTabs = (props) => {

    const [activePolicy, setActivePolicy] = React.useState(props.selectedPolicy ? props.selectedPolicy : props.policies[0]._id);

    return (
        <>
            <Row>
                <Col md="4" lg="3">
                    <Card className="bg-container-navy mb-4">
                        <CardHeader className="section-header">Resources</CardHeader>
                        <CardBody>
                            <Nav pills role="tablist">
                                {
                                    props.policies.map((policy, index) => {
                                        return (
                                            <NavItem key={index} id="policy-list-item">
                                                <NavLink
                                                    className={`mb-2 ${activePolicy === policy._id ? "active" : ""}`}
                                                    href="#"
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setActivePolicy(policy._id);
                                                    }}>
                                                    {policy.title}
                                                </NavLink>
                                            </NavItem>
                                        )
                                    })
                                }
                            </Nav>
                        </CardBody>
                    </Card>
                </Col>
                <Col md="8" lg="9">
                    <Card className="bg-container-navy">
                        <CardHeader className="section-header">Security Guidelines</CardHeader>
                        <CardBody className="ml-3 p-0">
                            <TabContent id="myTabContent" activeTab={activePolicy}>
                                {
                                    props.policies.map(policy => {
                                        return (
                                            <TabPane tabId={policy._id} key={policy._id} role="tabpanel">
                                                {/* <section className="mb-4">
                                                    {
                                                        <div id="policy-goal">
                                                            <p style={{ fontFamily: 'Lato', fontSize: '0.9rem', wordSpacing: '0.15rem' }}>{policy.description}</p>
                                                        </div>
                                                    }
                                                </section> */}
                                                <section>
                                                    <ListGroup flush>
                                                    {
                                                        policy.practices.map((practice, index) => {
                                                            return (
                                                                <ListGroupItem tag="button" className="policyItem bg-transparent text-white d-flex align-items-center" key={index}>
                                                                    <span id="list-number">{index+1}</span>
                                                                    <span id="list-text" style={{ fontFamily: 'Lato', fontSize: '0.9rem', wordSpacing: '0.15rem' }}>{practice.text}</span>
                                                                </ListGroupItem>
                                                            )
                                                        })
                                                    }
                                                    </ListGroup>
                                                </section>
                                            </TabPane>
                                        )
                                    })
                                }
                            </TabContent>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </>
    );
}

export default PolicyTabs;