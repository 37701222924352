import React from 'react';
import { Form, FormGroup, Input, Button, Alert, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { pwReset } from '../../state/actions/authActions';
import { clearErrors } from '../../state/actions/errorActions';
import './start.css';

class Reset extends React.Component {

    state = {
        password: '',
        confirmPassword: '',
        organization: '',
        msg: null,
        clientMsg: null,
        modalOpen: false,
        isReset: false
    };

    componentDidUpdate(prevProps) {

        const { error, pwWasReset } = this.props;              

        // check for new error
        if (error !== prevProps.error) {
            if (error.id === 'RESET_FAIL') {
                this.setState({ msg: error.msg.msg });
            }
            else {
                this.setState({ msg: null});
            }
        }
        else if (pwWasReset& !this.state.modalOpen && !this.state.isReset) {
            this.setState( {isReset: true});
            this.toggle();
        }
    }

    toggle = () => {
        // if the modal is chanaging open-closed, redirect to the signin page
        if(this.state.modalOpen) {
            this.props.history.push('/start/signin');
        }
        
        this.setState( { modalOpen: !this.state.modalOpen});
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });

        if(this.state.clientMsg) {
            this.setState({clientMsg: ''});
        } 
    };

    onSubmit = e => {
        e.preventDefault();

        this.props.clearErrors();

        const token = this.props.match.params.token;

        const { password, confirmPassword } = this.state;

        if (confirmPassword !== password) {
            this.setState({clientMsg: 'Passwords do not match'});
            return;
        }

        this.props.pwReset( {'password': password}, token);
    };

    render() {
        return (
            <React.Fragment>
                <div className="form-container">
                    {this.state.msg ? <Alert color="danger">{this.state.msg}</Alert> : null}
                    {this.state.clientMsg ? <Alert color="danger">{this.state.clientMsg}</Alert> : null}
                    {this.props.isDataLoading ? <Alert color="secondary">Please wait a moment...</Alert> : null}
                    <Form className="form" onSubmit={this.onSubmit} >
                        <h3>Password Reset</h3>
                        <FormGroup>
                            <Input type="password" name="password" placeholder='new password (min 8 chars)' bsSize='sm' onChange={this.onChange} autoComplete='new-password' required minLength="8" maxLength="64" />
                            <div className="mt-2">
                                <a className="startLink" href="https://1password.com/password-generator/" target="_blank" rel="noopener noreferrer">create a strong password</a>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Input type="password" name="confirmPassword" placeholder='confirm new password' bsSize='sm' onChange={this.onChange} autoComplete='new-password' required minLength="8" maxLength="64" />
                        </FormGroup>
                        { process.env.NODE_ENV !== 'production' && <Button className="form-button">Submit</Button> }
                        { process.env.NODE_ENV == 'production' && <Button disabled className="form-button">COMING SOON</Button> }
                    </Form>
                </div>
                <Modal isOpen={this.state.modalOpen} toggle={this.toggle}>
                    <ModalBody>
                        Click 'Proceed' to sign in with your new account password.
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button onClick={this.toggle} color="success">Proceed</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    }
}

Reset.propTypes = {
    isDataLoading: PropTypes.bool,
    pwWasReset: PropTypes.bool,
    error: PropTypes.object.isRequired,
    pwReset: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    isDataLoading: state.auth.isDataLoading,
    pwWasReset: state.auth.pwWasReset,
    error: state.error
});

export default connect(mapStateToProps, { pwReset, clearErrors })(Reset);
