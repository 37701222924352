import axios from 'axios';
import {
    SCENARIO_LOADING,
    SCENARIO_LOADED,
    SCENARIO_LOADING_FAILED,
    SET_STEP,
    RESULT_SEND_SUCCESS,
    RESULT_SEND_FAILED
} from '../actions/types';
import {
    FIRST_ATTEMPT_CORRECT,
    SECOND_ATTEMPT_CORRECT,
    SECOND_ATTEMPT_INCORRECT
} from '../../components/scenario/globals';
import { returnScenarioErrors } from '../actions/scenarioErrorActions';


// Action:  loadScenario
// Desc:    send AuthZ token for verification and retrieve scenario data
export const loadScenario = (defenderToken) => dispatch => {

    // set Scenario Loading state
    dispatch({ type: SCENARIO_LOADING });

    const config = {
        headers: {
            "Content-Type": "application/json",
            "x-auth-token": defenderToken
        }
    }

    axios.get('/api/scenarios', config)
        .then(response => {
            dispatch({ type: SCENARIO_LOADED, payload: response.data })
        })
        .catch(err => {
            // err.response is null when a timeout error occurs
            if (!err.response || !err.response.data || err.response.status === 500) {
                dispatch(returnScenarioErrors({ msg: "Error detected. Please try again later." }, 500));
            }
            else {
                dispatch(returnScenarioErrors(err.response.data, err.response.status));
            }

            dispatch({ type: SCENARIO_LOADING_FAILED });
        });
}

// Action:  setStep
// Desc:    Progress the scenario sequence
export const setStep = (nextStep) => dispatch => {

    if ([FIRST_ATTEMPT_CORRECT, SECOND_ATTEMPT_CORRECT, SECOND_ATTEMPT_INCORRECT].includes(nextStep)) {

        const config = {
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": sessionStorage.getItem('defenderToken')
            }
        }

        const body = JSON.stringify({ step: nextStep });

        axios.post('/api/scenarios/answers', body, config)
        .then(response => {
            dispatch({type: RESULT_SEND_SUCCESS, payload: response.data});
        })
            .catch(err => {
                // err.response is null when a timeout error occurs
                if (!err.response || !err.response.data || err.response.status === 500) {
                    dispatch(returnScenarioErrors({ msg: "Error detected. Please try again later." }, 500));
                }
                else {
                    dispatch(returnScenarioErrors(err.response.data, err.response.status));
                }
                dispatch({type: RESULT_SEND_FAILED, payload: nextStep});
            });              
    }
    else {
        dispatch({ type: SET_STEP, payload: nextStep });
    }
}
