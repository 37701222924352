import React from 'react';
import { Form, FormGroup, Input, InputGroup, Button, Alert, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login, clearMsg, verifyUser } from '../../state/actions/authActions';
import { clearErrors } from '../../state/actions/errorActions';
import './start.css';
import '../../App.css';

class Signin extends React.Component {

    state = {
        email: '',
        password: '',
        msg: null,
        modalOpen: false,
        signOutConfirmed: false
    };

    componentDidMount() {
        const { isAuthenticated, error, token } = this.props;

        // sign the user in automatically if a valid token exists
        if (token && !error.status && !isAuthenticated) {
            this.props.verifyUser();
        }

        else if(error.id === 'TOKEN_EXPIRED') {
            this.toggleModal();
        }
    }

    componentDidUpdate(prevProps, prevState) {

        const { error, isAuthenticated } = this.props;

        if (error !== prevProps.error) {
            if (error.id === 'LOGIN_FAIL' || error.id === 'ACCOUNT_LOADING_FAILED') {
                this.setState({ msg: error.msg.msg });
            }        
            else if(error.id === 'TOKEN_EXPIRED') {
                this.toggleModal();
            }    
            else {
                this.setState({ msg: null });
            }
        }
        else if (!error.status && isAuthenticated) {
            // redirect on successful authentication  
            this.props.history.push('/dashboard');
        }
    
        if (prevState.modalOpen !== this.state.modalOpen) {

            if (this.state.signOutConfirmed) {
                localStorage.removeItem('token');
                window.location.reload();
            }
        }
        
    }

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen });
    }

    confirmLogout = e => {
        e.preventDefault();

        this.setState({ signOutConfirmed: true });
        this.toggleModal();
    };

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    
    onSubmit = e => {
        e.preventDefault();

        this.props.clearErrors();
        this.props.clearMsg();

        const { email, password } = this.state;

        const account = {
            email,
            password
        };

        this.props.login(account);

    };

    render() {
        return (
            <React.Fragment>
                <div className="form-container">
                    {this.state.msg ? <Alert color="danger">{this.state.msg}</Alert> : null}
                    {this.props.isDataLoading ? <Alert color="secondary">Please wait a moment...</Alert> : null}
                    <Form className="form" onSubmit={this.onSubmit}>
                        <h3>Welcome Back</h3>
                        <FormGroup>
                            <Input required type="email" name="email" placeholder='email' bsSize='sm' onChange={this.onChange} autoComplete='username' />
                        </FormGroup>
                        <FormGroup>
                            <InputGroup>
                                <Input required type="password" name="password" placeholder='password' bsSize='sm' onChange={this.onChange} autoComplete='password' minLength="8" maxLength="64" />
                            </InputGroup>
                        </FormGroup>
                        {/* { process.env.NODE_ENV !== 'production' && <Button className="form-button">SIGN IN</Button> }
                        { process.env.NODE_ENV === 'production'&& <Button disabled className="form-button">COMING SOON</Button> } */}
                        <Button className="form-button">SIGN IN</Button>

                        <div className="mt-2 startLinks">
                            <Link to='/start/register' className="startLink">New member?</Link>
                            { process.env.NODE_ENV !== 'production' && <Link to='/start/forgot' className="startLink">Forgot password?</Link> }
                        </div>
                    </Form>
                    <Modal isOpen={this.state.modalOpen} size="sm" centered>
                        <ModalBody className="bg-light">
                            <p className="text-center mt-2"><span style={{ fontSize: '1.1rem', fontWeight: '600' }}>Account Protection</span></p>
                            <p className="mb-0">For security reasons your session was terminated due to inactivity.</p>
                        </ModalBody>
                        <ModalFooter className="bg-light">
                            <Button className="ml-auto" color="primary" onClick={this.confirmLogout}>Ok</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}

Signin.propTypes = {
    isDataLoading: PropTypes.bool,
    isAuthenticated: PropTypes.bool,
    token: PropTypes.string,
    error: PropTypes.object.isRequired,
    login: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    clearMsg: PropTypes.func.isRequired,
    verifyUser: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    isDataLoading: state.auth.isDataLoading,
    isAuthenticated: state.auth.isAuthenticated,
    token: state.auth.token,
    successMsg: state.auth.msg,
    error: state.error
});

export default connect(mapStateToProps, { login, clearErrors, clearMsg, verifyUser })(Signin);
