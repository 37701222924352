import React from 'react';
import Chart from 'react-apexcharts';

class AwarenessPieChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            options: {
                title: {
                    text: 'Awareness',
                    align: 'center',
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                      fontSize: '14px',
                      fontWeight: '550',
                    },
                },
                colors: ['#007bff', '#28a745', '#dc3545'],
                
                chart: {
                    type: 'pie',
                    height: 'auto'
                },

                labels: ["Correct", "Correction", "Incorrect"],

                dataLabels: {
                    formatter(val, opts) {
                        return [val.toFixed(1) + '%']
                    },
                },

                theme: {
                    mode: 'dark'        
                },

                legend: {
                    position: 'bottom',
                    offsetX: -10
                },
                responsive: [{
                    breakpoint: 992,
                    options: {
                      legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                      }
                    }
                }],
            },
        };
    }

    render() {

        const series = [this.props.correctFirstTime, this.props.correctSecondTime, this.props.incorrect];

        return (
            <div id="chart">
                <Chart options={this.state.options} series={series} type="pie" width="320"/>
            </div>
        );
    }
}

export default AwarenessPieChart;