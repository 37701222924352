import React, { useState } from 'react';
import { Container, Row, Col, Card, CardBody, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { GetStartedCard } from './cards/GetStartedCard';
import { HomeCard } from './cards/HomeCard';
import PoliciesChart from './charts/PoliciesChart';
import Header from '../../common/Header';
import PropTypes from 'prop-types';

let cardInfo = [
    { icon: 'fa fa-newspaper-o fa-2x text-secondary mr-2', header: "SCENARIOS", text: 'Exercises Completed', link: '/dashboard/program' , value: '', valueText: '' },
    { icon: 'fa fa-shield fa-2x text-primary mr-2', header: "AWARENESS", text: 'Total Policy Awareness', link: '/dashboard/awareness' , value: 0, valueText: '%' }, 
    { icon: 'fa fa-user-circle fa-2x text-success mr-2', header: "COVERAGE", text: 'Workforce Participants', link: '/dashboard/defenders' , value: '', valueText: '' },
    { icon: 'fa fa-line-chart fa-2x text-warning mr-2', header: "PARTICIPATION", text: 'Average Response Rate', link: '/dashboard/participation' , value: 0, valueText: '%' }, 
];

const HomePage = (props) => {

    const [redirect, setRedirect] = useState(false);
    const [selectedPolicy, setSelectedPolicy] = useState();

    const { defenders, statistics, policyStats, policies, name, policiesLoaded } = props;

    const handleColumnClick = policyId => {
        setSelectedPolicy(policyId);
        setRedirect(true);
    }

    let message = `Welcome ${name}`;

    // navigate to the program page if the colum chart has been clicked
    if (redirect) {
        return (
            <Redirect to={{ pathname: '/dashboard/program/', state: { "selectedPolicy": selectedPolicy } }} />
        )
    }

    // custom home page on first sign-in
    if (defenders == null || defenders.length === 0) {
        return (
            <React.Fragment>
                <Header message={message} title="Overview" icon="fa fa-home" />
                <Container className='component-container home-container'>
                    <Row>
                        <Col className="d-flex align-items-center justify-content-center">
                            <GetStartedCard />
                        </Col>
                    </Row>
                </Container>
            </React.Fragment>
        )
    }

    if (!policiesLoaded) {
        return <Spinner color="primary" />
    }
    
    let policyTitles = [];
    let policyIds = [];
    let policyAvgs = [];
    let totalFirst = 0;
    let totalSecond = 0;
    let totalSent = 0;

    policies.forEach(policy => {
        policyTitles.push(policy.title);
        policyIds.push(policy._id);

        let policyStat = policyStats.find(stat => {
            return stat.policy === policy._id;
        });

        if(!policyStat || policyStat.numSent === 0) {
            policyAvgs.push(null);
        }
        else {
            totalFirst += policyStat.numFirstCorrect;
            totalSecond += policyStat.numSecondCorrect;
            totalSent += policyStat.numSent;

            policyAvgs.push( Math.round( (policyStat.numFirstCorrect + policyStat.numSecondCorrect) * 100 / policyStat.numSent));  
        }
    });

    // calculate top-level information
    cardInfo[0].value = statistics.length;
    cardInfo[1].value = totalSent === 0 ? 0 : Math.round( (totalFirst + totalSecond) * 100 / totalSent);
    cardInfo[2].value = defenders.length;
    
    if(statistics.length > 0) {
        cardInfo[3].value = Math.round(statistics.reduce((total, stat) => { return total + (stat.numReply * 100) / (stat.numSent + stat.numUnverified) }, 0) / statistics.length);
    }

    return (
        <React.Fragment>
            <Header message={message} title="Overview" icon="fa fa-home" />
            <Container fluid className='component-container home-container'>
                <Row>
                    {
                        cardInfo.map((data, i) => 
                            <Col key={i} xs="12" md="6" lg="3" className="mb-4">
                                <HomeCard data={data} />
                            </Col>
                        )
                    }     
                </Row>
                <Row>
                    <Col xs="12" className="mb-4">
                        <Card className="bg-container-navy text-center">
                            <CardBody>
                                <PoliciesChart policyTitles={policyTitles} policyIds={policyIds} policyAvgs={policyAvgs} handleColumnClick={handleColumnClick} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    );
}

HomePage.propTypes = {
    defenders: PropTypes.array,
    statistics: PropTypes.array,
    policyStats: PropTypes.array,
    policies: PropTypes.array,
    name: PropTypes.string
}

const mapStateToProps = state => ({
    defenders: state.account.data.defenders,
    statistics: state.account.data.statistics,
    policyStats: state.account.data.policyStats,
    policies: state.account.data.policies,
    policiesLoaded: state.account.policiesLoaded,
    name: state.account.data.name
});

export default connect(mapStateToProps, null)(HomePage);