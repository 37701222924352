import React, { useState } from 'react';
import { NavLink as RRNavlink, withRouter } from 'react-router-dom'
import {
  Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Logout from './Logout';
import Logo from '../../../../assets/images/logo_423x60.png';
import '../profile.css';

const navItemArray = [
  { path: '/defender/profile', icon: "fa fa-home", text: 'Home' }
  // { path: '/defender/profile/policy', icon: "fa fa-book", text: 'Policy' }
];

const DashboardNavbar = (props) => {

  const [collapsed, setCollapsed] = useState(true);

  const toggleNavbar = () => setCollapsed(!collapsed);

  // if (!props.name) {
  //   return null;
  // }

  return (

    <div>
      <Navbar expand="lg" dark>
        <NavbarBrand href="/defender/profile" className="navbar-brand mr-auto">
          <img src={Logo} width="423px" height="60px" alt="Inside Defense Logo" />
        </NavbarBrand>

        <NavbarToggler onClick={toggleNavbar} />
        <Collapse isOpen={!collapsed} navbar>
          <Nav className="navbar-nav ml-auto" >
            {
              navItemArray.map((item, index) => {
                return (
                  <NavItem key={index}>
                    <NavLink tag={RRNavlink} exact to={item.path} >
                      <i className={item.icon} id="navIcon"></i>
                      {item.text}
                    </NavLink>
                  </NavItem>
                );
              })
            }

            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                <i className="fa fa-user-o" id="navIcon"></i>
                Account
              </DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag={RRNavlink} exact to="/defender/profile/settings">
                  <i className="fa fa-cogs" aria-hidden="true" id="navIcon"></i>
                  Settings
                </DropdownItem>
                <DropdownItem divider style={{ borderTop: '1px solid #1e1e2f' }} />
                <Logout history={props.history} />
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

DashboardNavbar.propTypes = {
  organization: PropTypes.string
}

const mapStateToProps = state => ({
  // name: state.account.data.name
});

export default connect(mapStateToProps, null)(withRouter(DashboardNavbar));

