import {
    ACCOUNT_LOADED,
    ACCOUNT_LOADING_FAILED,
    ADD_DEFENDER_FAIL,
    ADD_DEFENDER_SUCCESS,
    LOAD_SCENARIO_FAIL,
    LOAD_SCENARIO_SUCCESS,
    LOAD_POLICIES_FAIL,
    LOAD_POLICIES_SUCCESS,
    REMIND_DEFENDER_FAIL,
    REMIND_DEFENDER_SUCCESS,
    REMOVE_DEFENDER_FAIL,
    REMOVE_DEFENDER_SUCCESS,
    UPDATE_USER_FAIL,
    UPDATE_USER_SUCCESS

} from '../actions/types';

const initialState = {
    accountLoading: true,
    accountLoaded: false,
    policiesLoaded: false,
    isReminderSent: false,
    data: null
}

export default function accountReducer(state = initialState, action) {

    switch (action.type) {

        case ACCOUNT_LOADED:
            return {
                ...state,
                data: action.payload,
                accountLoaded: true,
                accountLoading: false
            };

        case ACCOUNT_LOADING_FAILED:
            localStorage.removeItem('token');
            return {
                ...state,
                data: null,
                accountLoading: false
            };

        case LOAD_SCENARIO_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    scenario: action.payload
                }
            }
        case LOAD_SCENARIO_FAIL:
            return {
                ...state,
                data: {
                    ...state.data,
                    scenario: null
                }
            }
        case LOAD_POLICIES_SUCCESS:
            return {
                ...state,
                policiesLoaded: true,
                data: {
                    ...state.data,
                    policies: action.payload
                }
            }
        case LOAD_POLICIES_FAIL:
            return {
                ...state,
                data: {
                    ...state.data,
                    policies: null
                }
            }

        case ADD_DEFENDER_SUCCESS:
        case REMOVE_DEFENDER_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    defenders: action.payload
                }
            };

        case REMIND_DEFENDER_SUCCESS:
            return {
                ...state,
                isReminderSent: true
            };

        case ADD_DEFENDER_FAIL:
        case REMOVE_DEFENDER_FAIL:
        case REMIND_DEFENDER_FAIL:
        case UPDATE_USER_FAIL:
            return {
                ...state
            };

        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                data: {
                    ...state.data,
                    name: action.payload.name,
                    organization: action.payload.organization
                }
            };
        default:
            return state
    }
}