import React from 'react';
import Axios from 'axios';
import { Form, FormText, Input, InputGroup, InputGroupAddon, Button, Modal, ModalBody, ModalFooter } from 'reactstrap';


class UserRegistration extends React.Component {

    state = {
        email: '',
        msg: '',
        modalOpen: false,
        error: false
    };

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();

        const { email } = this.state;

        // call API to add email to the waiting list 
        Axios.post('/api/users', { email: email },)
            .then(res => {
                this.setState({
                    msg: res.data.msg,
                    email: '',
                    error: false, 
                    modalOpen: true
                })
            })
            .catch(err => {
                this.setState({
                    msg: err.response.data.msg,
                    error: true,
                    modalOpen: true
                })

            })
    };

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen });
    }

    render() {
        return (
            <React.Fragment>
                <div className="userRegistration-form-container mt-5">
                    <Form className="form" onSubmit={this.onSubmit} >
                        <InputGroup>
                            <Input type="email" id="email" name="email" placeholder='email address' onChange={this.onChange} value={this.state.email} autoComplete="username" required />
                            <InputGroupAddon addonType="append">
                                <Button color="primary" className="site-button">Try it Now<i className="fa fa-rocket ml-2" aria-hidden="true"></i></Button>
                            </InputGroupAddon>
                        </InputGroup>
                        <FormText color="white" className="mt-3">
                            Sign up now to experience free cybersecurity drills for a week.
                        </FormText>
                    </Form>
                    <Modal isOpen={this.state.modalOpen} size="sm" centered>
                        <ModalBody className="bg-light">
                            <p className="text-center mt-2"><span style={{ fontSize: '1.1rem', fontWeight: '600' }}>{this.state.error ? 'Error' : 'Thanks for Joining'}</span></p>
                            <p className="mb-0">{this.state.msg}</p>
                        </ModalBody>
                        <ModalFooter className="bg-light">
                            <Button className="w-25 mx-auto" color="transparent" onClick={this.toggleModal}>Close</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </React.Fragment>
        );
    }
}

export default UserRegistration;
