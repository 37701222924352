export const ACCOUNT_DELETE_REQUEST_FAIL = 'ACCOUNT_DELETE_REQUEST_FAIL';
export const ACCOUNT_DELETE_REQUEST_SUCCESS = 'ACCOUNT_DELETE_REQUEST_SUCCESS';
export const ACCOUNT_LOADED = 'ACCOUNT_LOADED';
export const ACCOUNT_LOADING_FAILED = 'ACCOUNT_LOADING_FAILED';
export const ADD_DEFENDER_FAIL = 'ADD_DEFENDER_FAIL';
export const ADD_DEFENDER_SUCCESS = 'ADD_DEFENDER_SUCCESS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_MSG = 'CLEAR_MSG';
export const CLEAR_SCENARIO_ERRORS = 'CLEAR_SCENARIO_ERRORS';
export const DATA_ERROR = 'DATA_ERROR';
export const DATA_LOADING = 'DATA_LOADING';
export const DEFENDER_AUTHENTICATED = 'DEFENDER_AUTHENTICATED';
export const DEFENDER_AUTHENTICATING = 'DEFENDER_AUTHENTICATING';
export const DEFENDER_AUTHENTICATION_FAILED = ' DEFENDER_AUTHENTICATION_FAILED';
export const DEFENDER_LOADED = 'DEFENDER_LOADED';
export const DEFENDER_LOADING = 'DEFENDER_LOADING';
export const DEFENDER_LOADING_FAILED = 'DEFENDER_LOADING_FAILED';
export const GET_ERRORS = 'GET_ERRORS';
export const GET_SCENARIO = 'GET_SCENARIO';
export const GET_SCENARIO_ERRORS = 'GET_SCENARIO_ERRORS';
export const LOAD_SCENARIO_FAIL = 'LOAD_SCENARIO_FAIL';
export const LOAD_SCENARIO_SUCCESS = 'LOAD_SCENARIO_SUCCESS';
export const LOAD_POLICIES_FAIL = 'LOAD_POLICIES_FAIL';
export const LOAD_POLICIES_SUCCESS = 'LOAD_POLICIES_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const PROFILE_DELETE_REQUEST_FAIL = 'PROFILE_DELETE_REQUEST_FAIL';
export const PROFILE_DELETE_REQUEST_SUCCESS = 'PROFILE_DELETE_REQUEST_SUCCESS';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REMIND_DEFENDER_FAIL = 'REMIND_DEFENDER_FAIL';
export const REMIND_DEFENDER_SUCCESS = 'REMIND_DEFENDER_SUCCESS';
export const REMOVE_DEFENDER_FAIL = 'REMOVE_DEFENDER_FAIL';
export const REMOVE_DEFENDER_SUCCESS = 'REMOVE_SUCCESS';
export const REQUEST_RESET_FAIL = 'REQUEST_RESET_FAIL';
export const REQUEST_RESET_SUCCESS = 'REQUEST_RESET_SUCCESS';
export const RESET_FAIL = 'RESET_FAIL';
export const RESET_SUCCESS = 'RESET_SUCCESS';
export const RESULT_SEND_FAILED = 'RESULT_SEND_FAILED';
export const RESULT_SEND_SUCCESS = 'RESULT_SEND_SUCCESS';
export const SCENARIO_LOADED = 'SCENARIO_LOADED';
export const SCENARIO_LOADING = 'SCENARIO_LOADING';
export const SCENARIO_LOADING_FAILED = 'SCENARIO_LOADING_FAILED';
export const SET_STEP = 'SET_STEP';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const USER_LOADED = 'USER_LOADED';
export const USER_LOADING_FAILED ='USER_LOADING_FAILED';