import React from 'react';
import { Row, Col, Card, CardBody, CardText, Button, CardTitle, CardHeader } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setStep } from '../../../state/actions/scenarioActions';
import { SHOW_RISK_INFO } from '../globals';

class ReferenceInfo extends React.Component {

    handleClick = (e) => {
        this.props.setStep(SHOW_RISK_INFO);
    }

    render() {

        if (!this.props.scenarioIsLoaded) {
            return null;
        }

        const { reference } = this.props;

        // split the text into paragraphs for display
        const referenceParagraphs = reference.text.split('\n');

        return (
            <React.Fragment>
                <Row>
                    <Col lg="6" className="d-flex flex-column align-items-center">
                        <Card className="bg-transparent">
                            <CardHeader className="py-0">
                                <CardTitle tag="h6">CYBER NEWS</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {referenceParagraphs.map((paragraph, i) => <CardText key={i}>{paragraph}</CardText>)}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" className="d-flex flex-column align-items-center">
                        <Card className="bg-container-navy" style={{ height: "100%", width: "100%" }}>
                            <CardBody className="d-flex align-items-center justify-content-center">
                                <section id="reference-section">
                                    <blockquote className="mb-0">
                                        {reference.quote}
                                    </blockquote>
                                    <footer className="blockquote-footer smallText">
                                        <cite>
                                            <a href={reference.url} target="_blank" rel="noopener noreferrer">
                                                {reference.cite}
                                            </a>
                                        </cite>
                                    </footer>
                                </section>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem', textAlign: 'center' }}>
                    <Button color="primary" outline onClick={this.handleClick}>Next</Button>
                </div>
            </React.Fragment >
        );
    }
}

ReferenceInfo.propTypes = {
    reference: PropTypes.object,
    scenarioIsLoaded: PropTypes.bool,
    setStep: PropTypes.func
}

const mapStateToProps = state => ({
    reference: state.scenario.scenario.reference,
    scenarioIsLoaded: state.scenario.scenarioIsLoaded
});

export default connect(mapStateToProps, { setStep })(ReferenceInfo);
