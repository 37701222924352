import React from 'react';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, ListGroup, ListGroupItem, CardText } from 'reactstrap';
import { connect } from 'react-redux';
import { setStep } from '../../../state/actions/scenarioActions';
import PropTypes from 'prop-types';
import {
    SHOW_QUESTION,
    FIRST_ATTEMPT_CORRECT,
    FIRST_ATTEMPT_INCORRECT,
    SECOND_ATTEMPT_CORRECT,
    SECOND_ATTEMPT_INCORRECT
} from '../globals';

const Question = (props) => {

    const handleClick = (id) => {

        const { step, setStep } = props;
        const { answers } = props.scenario.question;

        let nextStep;

        if (id > 0 && id < 4) {
            if (answers[id - 1].checked) {
                nextStep = step === SHOW_QUESTION ? FIRST_ATTEMPT_CORRECT : SECOND_ATTEMPT_CORRECT;
            }
            else {
                nextStep = step === SHOW_QUESTION ? FIRST_ATTEMPT_INCORRECT : SECOND_ATTEMPT_INCORRECT;
            }

            setStep(nextStep);
        }
    }

    if (!props.scenarioIsLoaded) {
        return null;
    }

    const { scenario } = props;

    // split the text into paragraphs for display
    const questionParagraphs = scenario.question.text.split('\n');

    return (
        <React.Fragment>
            <Row className="mb-3">
                <Col lg="6" className="d-flex flex-column align-items-center">
                    <Card className="bg-transparent">
                        <CardHeader className="py-0">
                            <CardTitle tag="h6">CYBER SCENARIO</CardTitle>
                        </CardHeader>
                        <CardBody>
                            {questionParagraphs.map((paragraph, i) => <CardText key={i}>{paragraph}</CardText>)}
                        </CardBody>
                    </Card>
                </Col>
                <Col lg="6" className="d-flex flex-column align-items-center">
                    <Card className="bg-container-navy" style={{ height: "100%", width: "100%" }}>
                        <CardBody className="d-flex flex-column justify-content-center">
                            <ListGroup flush>
                                {
                                    scenario.question.answers.map((answer, index) => {
                                        return (
                                            <ListGroupItem tag="button" onClick={() => handleClick(index + 1)} id={answer.id} className="policyItem bg-transparent d-flex align-items-center text-white" key={index}>
                                                <span id="list-number">{index + 1}</span>
                                                <span id="list-text">{answer.text}</span>
                                            </ListGroupItem>
                                        )
                                    })
                                }
                            </ListGroup>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

Question.propTypes = {
    step: PropTypes.number,
    setStep: PropTypes.func.isRequired,
    scenario: PropTypes.object.isRequired,
    scenarioIsLoaded: PropTypes.bool,
    resultIsSent: PropTypes.bool
}

const mapStateToProps = state => ({
    step: state.scenario.step,
    scenario: state.scenario.scenario,
    scenarioIsLoaded: state.scenario.scenarioIsLoaded,
    email: state.scenario.email,
    resultIsSent: state.scenario.resultIsSent
})

export default connect(mapStateToProps, { setStep })(Question);
