import React from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Switch, Route, withRouter, Redirect, useLocation } from 'react-router-dom';
import './start.css';
import Signin from './Signin';
import Contact from './Contact';
import Register from './Register';
import ForgotPw from './ForgotPw';
import Reset from './Reset';
import SiteNavbar from '../landing/Navbar';
import AccountConfirmation from './AccountConfirmation';
import DeleteConfirmation from './DeleteConfirmation';
import UserConfirmation from './UserConfirmation';
import Footer from '../common/Footer';

function Start() {

    let location = useLocation();

    return (
        <div className="top-container">
            <nav><SiteNavbar /></nav>
            <img src='/site-img.jpg' alt='site background' height="1080px" width="1920px" style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
            <div className="bg-img-overlay"></div>
            <div className="start">
                <TransitionGroup>
                    <CSSTransition key={location.pathname} timeout={1000} classNames='fade'>
                        <Switch location={location}>
                            <Route exact path='/start/signin' component={Signin} />
                            <Route exact path='/start/contact-us' component={Contact} />
                            <Route exact path='/start/register' component={Register} />
                            <Route exact path='/start/forgot' component={ForgotPw} />
                            <Route exact path='/start/reset/:token' component={Reset} />
                            <Route exact path='/start/accountConfirmation/:confirmationToken' component={AccountConfirmation} />
                            <Route exact path='/start/deleteConfirmation/:confirmationToken' component={DeleteConfirmation} />
                            <Route exact path='/start/userConfirmation/:defenderToken' component={UserConfirmation} />
                            <Redirect from="*" to="/start/signin" />
                        </Switch>
                    </CSSTransition>
                </TransitionGroup>
            </div>
            <Footer className="mt-3" />
        </div>
    );
}

export default withRouter(Start);