import React from 'react';
import Chart from 'react-apexcharts';

class ColumnChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [{
        name: 'Response',
        data: [...this.props.responding]
      }, {
        name: 'No Response',
        data: [...this.props.noResponse]
      }, {
        name: 'Unverified',
        data: [...this.props.notVerified]
      }],
      options: {
        colors: ['#007bff', '#dc3545', '#6c757d'],        
        tooltip: {
          x: {
            formatter: (value, series) => {

              return this.props.titles[series.dataPointIndex];
            }
          }
        },
        theme: {
          mode: 'dark'        
        },
        grid: {          
          yaxis: {
              lines: {
                  show: false
              }
          }          
        },        
        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const { dataPointIndex }  = config;

              this.props.handleColumnClick(dataPointIndex);              
            }
          }
        },        
        xaxis: {
          categories: [...this.props.dates],
        },
        yaxis: {
          title: {
              text: 'Scenario Participation %',
          },
          tickAmount: 4
        },

        fill: {
          opacity: 0.5
        },
        
        legend: {
          position: 'bottom',
          labels: {
            colors: 'white'
          }          
        },
      },
    };
  }

  render() {

    return (
      <div id="chart">
        <Chart options={this.state.options} series={this.state.series} type="bar" height={250} />
      </div>
    );
  }
}

export default ColumnChart;