import React from 'react';
import { Card, CardHeader, CardBody, CardText } from 'reactstrap';
// import { Link } from 'react-router-dom';

export const InfoCard = (props) => {

    return (
        <Card className="bg-container-navy text-center" style={{ height: '100%' }}>
            <CardHeader className="d-flex align-items-center justify-content-center pb-0">
                <span style={{ fontSize: '1rem' }}>{props.data.header}</span>
            </CardHeader>
            <CardBody className="d-flex flex-column align-items-center justify-content-center ">                
                {/* <Link to={{ pathname: props.data.link }}> */}
                    <CardText className="text-white" tag="h1"><strong>{props.data.prepend}{props.data.value}{props.data.valueText}</strong></CardText>
                {/* </Link> */}
                <CardText className="smallText mb-1 text-secondary"><strong>{props.data.text}</strong></CardText>
            </CardBody>
        </Card>
    )
}