import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
// import { loadPolicies } from '../../state/actions/accountActions';
import { loadDefender } from '../../state/actions/defenderActions';

import Navbar from './profile/nav/Navbar';
import HomePage from './profile/home/HomePage';
// import Policy from './profile/policy/Policy';
import Settings from './profile/settings/Settings';
import Footer from '../common/Footer';
// import { PageNotFound } from './profile/error/PageNotFound';
import PropTypes from 'prop-types';
import './profile/profile.css';

class Defender extends React.Component {

    componentDidMount() {
        if (!this.props.defenderLoaded) {
            this.props.loadDefender(sessionStorage.getItem('defenderToken'));
        }
    }

    render() {

        // const { location, error, defenderLoading, defenderLoaded } = this.props;
        const { location, defenderLoading, error, defenderLoaded } = this.props;

        if (defenderLoading ) {
            return <Spinner />
        }
        
        else if (error.status) {
            console.log("status: ",error.status);
            if(error.id === 'TOKEN_EXPIRED') {
                return <Redirect to='/' />               
            }
            else if(!defenderLoaded) {
                return <Redirect to='/error' />
            }
        }         

        return (
            <div className="dashboard-container">
                <Navbar />
                <TransitionGroup className="transition-group">
                    <CSSTransition key={location.key} timeout={1000} classNames='fade'>
                        <section className="transition-container">
                            <Switch location={location}>
                                <Route exact path='/defender/profile' component={HomePage} />
                                {/* <Route exact path='/defender/profile/policy' component={Policy} /> */}
                                <Route exact path='/defender/profile/settings' component={Settings} />
                                {/* <Route component={PageNotFound} /> */}
                            </Switch>                            
                            <Footer />   
                        </section>
                    </CSSTransition>
                </TransitionGroup>             
            </div>
        );
    }
}

Defender.propTypes = {
    loadDefender: PropTypes.func.isRequired,
    // loadPolicies: PropTypes.func.isRequired,
    // policiesLoaded: PropTypes.bool,
    error: PropTypes.object,
    defenderIsLoading: PropTypes.bool,
    defenderLoaded: PropTypes.bool
};

const mapStateToProps = state => ({
    defenderIsLoading: state.defender.defenderIsLoading,
    defenderLoaded: state.defender.defenderLoaded,
    // policiesLoaded: state.account.policiesLoaded,
    error: state.error
});

// export default withRouter(connect(mapStateToProps, { loadDefender, loadPolicies })(Dashboard));
export default withRouter(connect(mapStateToProps, { loadDefender })(Defender));