import React from 'react';
import { Form, FormGroup, Input, Button, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { clearMsg } from '../../state/actions/authActions';
import { requestReset } from '../../state/actions/authActions';
import { clearErrors } from '../../state/actions/errorActions';
import './start.css';
import '../../App.css';

class ForgotPw extends React.Component {

    state = {
        email: '',
        msg: null
    };

    componentDidUpdate(prevProps) {

        const { error } = this.props;

        if (error !== prevProps.error) {
            if (error.id === 'REQUEST_RESET_FAIL') {
                this.setState({ msg: error.msg.msg });
            }
            else {
                this.setState({ msg: null });
            }
        }
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });
    };

    onSubmit = e => {
        e.preventDefault();

        this.props.clearErrors();
        this.props.clearMsg();

        this.props.requestReset({ email: this.state.email });
    };


    render() {
        return (
            <React.Fragment>
                <div className="form-container">
                    {this.state.msg ? <Alert color="danger">{this.state.msg}</Alert> : null}
                    {this.props.isDataLoading ? <Alert color="secondary">Please wait a moment...</Alert> : null}
                    {this.props.resetEmailSent ? <Alert color="success">{this.props.successMsg}</Alert> : null}
                    <Form className="form" onSubmit={this.onSubmit}>
                        <h3>Forgot Password</h3>
                        <FormGroup>
                            <Input required type="email" name="email" placeholder='email' bsSize='sm' onChange={this.onChange} autoComplete='off' />
                        </FormGroup>
                        <Button className="form-button">SEND</Button>

                        <div className="mt-2 w-100 startLinks">
                            <Link to='/start/register' className="startLink">Sign Up</Link>
                            <Link to='/start/signin' className="startLink">Sign In</Link>
                        </div>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

ForgotPw.propTypes = {
    isDataLoading: PropTypes.bool,
    resetEmailSent: PropTypes.bool,
    error: PropTypes.object.isRequired,
    requestReset: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    clearMsg: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    isDataLoading: state.auth.isDataLoading,
    resetEmailSent: state.auth.resetEmailSent,
    successMsg: state.auth.msg,
    error: state.error
});

export default connect(mapStateToProps, { requestReset, clearErrors, clearMsg })(ForgotPw);
