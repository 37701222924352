import React from 'react';
import { connect } from 'react-redux';
import { Container, Spinner } from 'reactstrap';
import Proptypes from 'prop-types';
import Header from '../../common/Header';
import ReportTabs from './ReportTabs';

class Program extends React.Component {

    render() {
        const { name, statistics, policies, policiesLoaded } = this.props;
        const message = `Welcome ${name}`;
        let mode = '';
        let selectedScenario = null;
        let selectedPolicy = null;

        if (!policiesLoaded ) {
            return <Spinner />
        }
        
        if(this.props.location.state) {
            mode = this.props.location.state.mode;  
            selectedScenario = this.props.location.state.selectedScenario;  
            selectedPolicy = this.props.location.state.selectedPolicy;  
        }

        let filteredStatistics = statistics;

        if(mode === 'vulnerabilities') {
            filteredStatistics = [...statistics].sort((a, b) => b.numIncorrect - a.numIncorrect).filter(stat => stat.numIncorrect > 0);
        }
        else if (mode === 'improvement') {
            filteredStatistics = [...statistics].sort((a, b) => b.numSecondCorrect - a.numSecondCorrect).filter(stat => stat.numSecondCorrect > 0);
        }
         
        return (
            <React.Fragment>
                <Header message={message} title="Program Results" icon="fa fa-list" />

                <Container fluid className="component-container program-container">     
                    <ReportTabs statistics={filteredStatistics} policies={policies} selectedScenario={selectedScenario} selectedPolicy={selectedPolicy} />
                </Container>
            </React.Fragment>
        )
    }
}

Program.propTypes = {
    name: Proptypes.string,
    statistics: Proptypes.array,
    policies: Proptypes.array,
    policiesLoaded: Proptypes.bool
}

const mapStateToProps = state => ({
    name: state.account.data.name,
    statistics: state.account.data.statistics,
    policies: state.account.data.policies,
    policiesLoaded: state.account.policiesLoaded
})

export default connect(mapStateToProps, null)(Program);