import React from 'react';
import { Switch, Route, withRouter, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import { loadAccount, loadPolicies } from '../../state/actions/accountActions';
import Navbar from './nav/Navbar';
import HomePage from './home/HomePage';
import Awareness from './awareness/Awareness';
import Defenders from './defenders/Defenders';
import Policy from './policy/Policy';
import Participation from './participation/Participation';
import Program from './program/Program';
import Settings from './settings/Settings';
import Footer from '../common/Footer';
import { PageNotFound } from './error/PageNotFound';
import PropTypes from 'prop-types';
import './dashboard.css';

class Dashboard extends React.Component {

    componentDidMount() {
        if (!this.props.accountLoaded) {
            this.props.loadAccount(this.props.history);
        }
    }

    componentDidUpdate() {
        if(this.props.accountLoaded && !this.props.policiesLoaded) {
            this.props.loadPolicies();
        }
    }

    render() {

        const { location, error, accountLoading, accountLoaded } = this.props;

        if (accountLoading ) {
            return <Spinner />
        }
        
        else if (error.status) {
            if(error.id === 'TOKEN_EXPIRED') {
                return <Redirect to='/start/signin' />               
            }
            else if(!accountLoaded) {
                return <Redirect to='/error' />
            }
        }         

        return (
            <div className="dashboard-container">
                <Navbar />
                <TransitionGroup className="transition-group">
                    <CSSTransition key={location.key} timeout={1000} classNames='fade'>
                        <section className="transition-container">
                            <Switch location={location}>
                                <Route exact path='/dashboard' component={HomePage} />
                                <Route exact path='/dashboard/defenders' component={Defenders} />
                                <Route exact path='/dashboard/participation' component={Participation} />
                                <Route exact path='/dashboard/awareness' component={Awareness} />
                                <Route exact path='/dashboard/policy' component={Policy} />
                                <Route exact path='/dashboard/program' component={Program} />
                                <Route exact path='/dashboard/settings' component={Settings} />
                                <Route component={PageNotFound} />
                            </Switch>                            
                            <Footer />   
                        </section>
                    </CSSTransition>
                </TransitionGroup>             
            </div>
        );
    }
}

Dashboard.propTypes = {
    loadAccount: PropTypes.func.isRequired,
    loadPolicies: PropTypes.func.isRequired,
    accountLoading: PropTypes.bool,
    accountLoaded: PropTypes.bool,
    policiesLoaded: PropTypes.bool,
    error: PropTypes.object,
};

const mapStateToProps = state => ({
    accountLoading: state.account.accountLoading,
    accountLoaded: state.account.accountLoaded,
    policiesLoaded: state.account.policiesLoaded,
    isAuthenticated: state.auth.isAuthenticated,
    error: state.error
});

export default withRouter(connect(mapStateToProps, { loadAccount, loadPolicies })(Dashboard));