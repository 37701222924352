import {
    DEFENDER_LOADING,
    DEFENDER_LOADED,
    DEFENDER_LOADING_FAILED,
    DEFENDER_AUTHENTICATED,
    DEFENDER_AUTHENTICATING,
    DEFENDER_AUTHENTICATION_FAILED,
    PROFILE_DELETE_REQUEST_FAIL,
    PROFILE_DELETE_REQUEST_SUCCESS,
} from '../actions/types';

const initialState = {
    defenderToken: sessionStorage.getItem('defenderToken'),
    defenderIsAuthenticating: true,
    defenderAuthenticated: false,
    defenderIsLoading: true,
    defenderLoading: true,
    defenderLoaded: false
}

export default function defenderReducer(state = initialState, action) {

    switch (action.type) {
        case DEFENDER_AUTHENTICATING:
            sessionStorage.removeItem('defenderToken');
            return {
                ...state,
                defenderIsAuthenticating: true
            };

        case DEFENDER_AUTHENTICATED:
            sessionStorage.setItem('defenderToken', action.payload.defenderToken);
            return {
                ...state,
                ...action.payload,
                defenderIsAuthenticating: false,
                defenderAuthenticated: true
            };

        case DEFENDER_AUTHENTICATION_FAILED:
            sessionStorage.removeItem('defenderToken');
            return {
                ...state,
                defenderIsAuthenticating: false,
                defenderAuthenticated: false
            };

        case DEFENDER_LOADING:
            return {
                ...state,
                defenderIsLoading: true,
                defenderLoading: true
            };

        case DEFENDER_LOADED:
            return {
                ...state,
                ...action.payload,
                defenderIsLoading: false,
                defenderLoading: false,
                defenderLoaded: true
            };

        case DEFENDER_LOADING_FAILED:
            sessionStorage.removeItem('defenderToken');
            return {
                ...state,
                defenderIsLoading: false,
                defenderLoading: false
            };
        
        case PROFILE_DELETE_REQUEST_SUCCESS:
            sessionStorage.removeItem('defenderToken');
            return {
                ...state,
                defenderToken: null,
                defenderIsLoading: false,
                defenderLoading: false,
                defenderLoaded: false,
                defenderIsAuthenticating: false,
                defenderAuthenticated: false
            };
        
        default:
            return state;
    }
}
