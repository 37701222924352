import React from 'react';
import { connect } from 'react-redux';
import { Spinner, Container } from 'reactstrap';
import Header from '../../common/Header';
import PolicyTabs from './PolicyTabs';
import PropTypes from 'prop-types';

class Policy extends React.Component {

    render() {
        const { name, policies, policiesLoaded } = this.props;

        // Note: if policies loading fails, this creates an infinite loop
        if (!policiesLoaded ) {
            return <Spinner />
        }

        let selectedPolicy = undefined;

        if(this.props.location.state) {
            selectedPolicy = this.props.location.state.selectedPolicy;
        }

        let message = `Welcome ${name}`;

        if (!policies) {
            return (
                <Container className="component-container text-center">
                    <Spinner color="primary">Loading...</Spinner>
                </Container>
            )
        }

        return (
            <React.Fragment>
                <Header message={message} title="Cyber Security Policy" icon="fa fa-book" />

                <Container fluid className="component-container">
                    {<PolicyTabs policies={policies} selectedPolicy={selectedPolicy} /> }
                </Container>
            </React.Fragment>
        )
    }
}

Policy.propTypes = {
    name: PropTypes.string,
    policies: PropTypes.array,
    policiesLoaded: PropTypes.bool
}

const mapStateToProps = state => ({
    name: state.account.data.name,
    policies: state.account.data.policies,
    policiesLoaded: state.account.policiesLoaded
});

export default connect(mapStateToProps, null)(Policy);

