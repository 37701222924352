import React from 'react';
import Chart from 'react-apexcharts';

class ParticipationPieChart extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            options: {
                title: {
                    text: 'Participation',
                    align: 'center',
                    offsetX: 0,
                    offsetY: 0,
                    floating: false,
                    style: {
                      fontSize: '14px',
                      fontWeight: '550',
                    },
                },

                colors: ['#007bff', '#dc3545', '#6c757d'],
                
                chart: {
                    type: 'pie',
                    height: 'auto'
                },
                
                labels: ["Response", "No Response", "Unverified"],
                
                dataLabels: {
                    formatter(val, opts) {
                        return [val.toFixed(1) + '%']
                    }
                },

                theme: {
                    mode: 'dark'        
                },
                
                legend: {
                    position: 'bottom',
                    offsetX: -10
                },
                responsive: [{
                    breakpoint: 992,
                    options: {
                      legend: {
                        position: 'bottom',
                        offsetX: -10,
                        offsetY: 0
                      }
                    }
                }],
            },
        };
    }

    render() {

        const series = [this.props.responding, this.props.noResponse, this.props.notVerified];

        return (
            <div id="chart">
                <Chart options={this.state.options} series={series} type="pie" width="320" />
            </div>
        );
    }
}

export default ParticipationPieChart;