import React from 'react';
import { Row, Col, Card, CardHeader, CardBody, CardTitle, CardText, Button } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setStep } from '../../../state/actions/scenarioActions';
import { SHOW_QUESTION } from '../globals';

class RiskInfo extends React.Component {

    handleClick = (e) => {
        this.props.setStep(SHOW_QUESTION);
    }

    render() {

        if (!this.props.scenarioIsLoaded) {
            return null;
        }

        const { risk } = this.props;

        // split the text into paragraphs for display
        const riskParagraphs = risk.text.split('\n');

        return (
            <React.Fragment>
                <Row>
                    <Col lg={{ size: 10, offset: 1 }} className="d-flex flex-column align-items-center">
                        <Card className="bg-transparent" style={{ maxWidth: "32rem" }}>
                            <CardHeader className="py-0">
                                <CardTitle tag="h6">KNOW YOUR RISK</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {riskParagraphs.map((paragraph, i) => <CardText key={i}>{paragraph}</CardText>)}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <div style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem', textAlign: 'center' }}>
                    <Button color="primary" outline onClick={this.handleClick}>Next</Button>
                </div>
            </React.Fragment>
        );
    }
}

RiskInfo.propTypes = {
    risk: PropTypes.object,
    scenarioIsLoaded: PropTypes.bool,
    setStep: PropTypes.func
}

const mapStateToProps = state => ({
    risk: state.scenario.scenario.risk,
    scenarioIsLoaded: state.scenario.scenarioIsLoaded
});

export default connect(mapStateToProps, { setStep })(RiskInfo);
