import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardImg, Button, CardBody, CardTitle } from 'reactstrap';

import teamImg from '../../../../assets/images/dashboard/team_600x400.jpg';

export const GetStartedCard = (props) => {
    return (
        <Card className='sm-4 shadow p-0 border-0 mx-auto' id="get-started-card" body>
            <div className="inner">
                <CardImg top width="100%" src={teamImg} alt="Card image" />
            </div>
            <CardBody className="text-center bg-container-navy">
                <CardTitle>GET STARTED</CardTitle>
                <p>Create Your Team</p>
                <Button color="primary" outline className="w-50 mx-auto" block tag={Link} to={'/dashboard/defenders'}>Go</Button>
            </CardBody>
        </Card>
    );
}