import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import './App.css';
import PrivateRoute from './PrivateRoute';
import DefenderPrivateRoute from './components/defender/PrivateRoute';
import Dashboard from './components/dashboard';
import DefenderAuthn from './components/defender/auth';
import Scenario from './components/scenario';
import Defender from './components/defender';
import Start from './components/start/Start'; 
import Landing from './components/landing/Landing';
import Error from './components/landing/Error';
import Privacy from './components/landing/Privacy';

class App extends React.Component {

  render() {

    return (
      <div className="App">
        <Switch>
          <Route exact path='/' component={Landing} /> 
          <Route exact path='/privacy' component={Privacy} />
          <Route exact path='/scenario/:defenderToken' component={Scenario} />
          <Route path='/defender/auth/:defenderToken' component={DefenderAuthn} />
          <Route path='/error' component={Error} />
          <Route path='/start' component={Start} />
          <PrivateRoute path='/dashboard' component={Dashboard} />
          <DefenderPrivateRoute path='/defender' component={Defender} />
          <Redirect from="*" to="/start/signin" />
        </Switch>
      </div>
    );
  }
}

export default App;

