import { configureStore } from '@reduxjs/toolkit';
import errorReducer from './reducers/errorReducer';
import authReducer from './reducers/authReducer';
import accountReducer from './reducers/accountReducer';
import defenderReducer from './reducers/defenderReducer';
import scenarioReducer from './reducers/scenarioReducer';
import scenarioErrorReducer from './reducers/scenarioErrorReducer';

const initialState = {};
const store = configureStore({
    reducer: {
        error: errorReducer,
        auth: authReducer,
        account: accountReducer,
        defender: defenderReducer,
        scenario: scenarioReducer,
        scenarioError: scenarioErrorReducer
    }, 
    initialState
});

export default store;

