import React from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ColumnChart from './charts/ColumnChart';
import LineChart from './charts/LineChart';
import { ActionCard } from './cards/actionCard';

import Header from '../../common/Header';

let cardInfo = [
    { icon: 'fa fa-user-circle fa-2x text-danger mr-2', header: "Inactive Users", text: 'Send Reminder', path: '/dashboard/defenders' , filter: 'inactive', value: '', valueText: 'Users' },
    { icon: 'fa fa-question-circle fa-2x text-secondary mr-2', header: "Unverified Users", text: 'Remove Invalid Addresses', path: '/dashboard/defenders' , filter: 'not verified', value: '', valueText: 'Users' },
];

class Participation extends React.Component {

    state = {
        "redirect": false,
        "targetId": null
    }

    handleColumnClick = chartIndex => {
        
        let targetId = this.props.statistics[chartIndex]._id;
        this.setState({ redirect: true, targetId: targetId })
    }

    render() {

        if (this.state.redirect) {
            return (
                <Redirect to={{ pathname: '/dashboard/program/', state: { "selectedScenario": this.state.targetId } }} />
            )
        }

        const { name, statistics, defenders } = this.props;

        let numScenarios = statistics.length;
        let message = `Welcome ${name}`;
        let lowParticipation = 0;
        let verified =[], responding = [], noResponse = [], notVerified = [], dates = [], titles = [];

        if (numScenarios > 0) {

            for (let i = 0; i < numScenarios; i++) {
                let formattedDate = new Date(statistics[i].sentDate).toLocaleDateString('en-us', { month: 'short', day: 'numeric' });
                dates.push(formattedDate);
                titles.push(statistics[i].scenario.title);
                verified.push(statistics[i].numSent);
                responding.push(statistics[i].numReply);
                noResponse.push(statistics[i].numSent - statistics[i].numReply);
                notVerified.push(statistics[i].numUnverified);
            }

            defenders.forEach(defender => {

                if (defender.status === "inactive") {
                    lowParticipation++;
                }
            })

            cardInfo[0].value = lowParticipation;
            cardInfo[1].value = statistics[numScenarios-1].numUnverified;
            
        }
        return (
            <React.Fragment>
                <Header message={message} title="Team Participation" icon="fa fa-line-chart" />

                <Container fluid className="component-container">
                    <Row>
                        <Col xs="12" lg="6" className="mb-4">
                            <Card className="bg-container-navy">
                            <CardHeader className="d-flex align-items-center justify-content-center pb-0">
                                    <span style={{ fontSize: '1rem' }}>Onboarding Trend</span>
                                </CardHeader>
                                <LineChart 
                                    verified={verified}
                                    dates={dates}
                                />
                            </Card>
                        </Col>
                        {
                            cardInfo.map((data, i) =>
                                <Col key={i} xs="12" md="6" lg="3" className="mb-4">
                                    <ActionCard data={data} />
                                </Col>
                            )
                        }
                    </Row>
                    <Row className="justify-content-center">
                        <Col xs="12" className="mb-4 columnChart">
                            <Card className="bg-container-navy">
                                <CardBody>
                                    <ColumnChart
                                        handleColumnClick={this.handleColumnClick}
                                        responding={responding}
                                        noResponse={noResponse}
                                        notVerified={notVerified}
                                        titles={titles}
                                        dates={dates} />
                                </CardBody>
                            </Card>
                        </Col>                        
                    </Row>
                </Container>
            </React.Fragment>
        );
    }
}

Participation.propTypes = {
    statistics: PropTypes.array.isRequired,
    defenders: PropTypes.array.isRequired,
    name: PropTypes.string
}

const mapStateToProps = state => ({
    statistics: state.account.data.statistics,
    defenders: state.account.data.defenders,
    name: state.account.data.name
});

export default connect(mapStateToProps, null)(Participation);
