import React from 'react';
import { Row, Col, Card, CardHeader, CardTitle, CardBody, Button, CardText, ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setStep } from '../../../state/actions/scenarioActions';
import { SHOW_QUESTION_AGAIN } from '../globals';

class Policy extends React.Component {

    handleClick = (e) => {
        this.props.setStep(SHOW_QUESTION_AGAIN);
    }

    render() {

        if (!this.props.scenarioIsLoaded) {
            return null;
        }

        const { practices, title } = this.props;

        return (
            <React.Fragment>
                <Row className="mb-3">
                    <Col lg="6" className="d-flex flex-column align-items-center">
                        <Card className="bg-transparent mb-2">
                            <CardHeader className="py-0">
                                <CardTitle id="wrong-answer" tag="h6">INCORRECT</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <CardText>The answer you selected is not the recommended practice for this scenaro.</CardText>
                                <CardText>Take a moment to review the list of defensive practices for {title}</CardText>
                                <CardText>When you're ready, click the button below to try again.</CardText>

                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" className="d-flex flex-column align-items-center">
                        <Card className="bg-container-navy">
                            <CardHeader className='text-white text-center text-uppercase'>
                                {title} POLICY
                            </CardHeader>
                            <CardBody className="pt-0">
                                <ListGroup flush>
                                    {
                                        practices.map((practice, index) => {
                                            return (
                                                <ListGroupItem className="bg-transparent practiceItem" key={index}>
                                                    <span id="practice-text">{index + 1}. {practice.text}</span>
                                                </ListGroupItem>
                                            )
                                        })
                                    }
                                </ListGroup>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <div style={{ width: '100%', marginTop: '2rem', marginBottom: '2rem', textAlign: 'center' }}>
                    <Button color="primary" outline onClick={this.handleClick}>Try Again</Button>
                </div>

            </React.Fragment >
        );
    }
}

Policy.propTypes = {
    practices: PropTypes.array,
    scenarioIsLoaded: PropTypes.bool,
    setStep: PropTypes.func,
    title: PropTypes.string
}

const mapStateToProps = state => ({
    practices: state.scenario.practices,
    scenarioIsLoaded: state.scenario.scenarioIsLoaded,
    title: state.scenario.scenario.title
});

export default connect(mapStateToProps, { setStep })(Policy);
