import React from 'react';
import Chart from 'react-apexcharts';

class PoliciesChart extends React.Component {
    constructor(props) {

        super(props);

        this.state = {

            series: [{
                name: 'Policy Awareness',
                data: [...this.props.policyAvgs]
            }],
            options: {                      
                tooltip: {
                    enabled: false,
                },    
                chart: {
                    height: 250,
                    type: 'bar',
                    stacked: false,
                    stackType: '100%',
                    toolbar: {
                        show: false
                    },
                    events: {
                        dataPointSelection: (event, chartContext, config) => {
                          const { dataPointIndex }  = config;

                          let policyId =  this.props.policyIds[dataPointIndex];
            
                          this.props.handleColumnClick(policyId);              
                        }
                      }
                },
                grid: {
                    yaxis: {
                      lines: {
                        show: false
                      }
                    }
                },
                theme: {
                    mode: 'dark'        
                },                
                dataLabels: {
                    enabled: true,
                    formatter: function(val) {
                        if(val !== null) {
                            return val + "%";
                        }
                    },
                    offsetY: 15,
                },
                stroke: {
                    width: 0
                },
                xaxis: {
                    labels: {
                        rotate: -45,
                    },
                    categories: [...this.props.policyTitles],
                    tickPlacement: 'on'
                },
                yaxis: {
                    title: {
                        text: 'Policy Awareness %',
                    },
                    tickAmount: 4
                },
                fill: {
                    type: 'solid',
                    colors: ['#1A73E8'] 
                }
            },
        };
    }

    render() {
        return (
            <div id="chart">
                <Chart options={this.state.options} series={this.state.series} type="bar" height={250} />
            </div>
        );
    }
}

export default PoliciesChart;