import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavlink } from 'react-router-dom';
import Logo from '../../assets/images/logo_423x60.png';

const SiteNavbar = (props) => {

  const [collapsed, setCollapsed] = useState(true);
  // const [scrolled, setScrolled] = useState(false);

  // const onScroll = () => {
  //   if (window.scrollY > 80) {
  //     setScrolled(true);
  //   } 
  //   else {
  //     setScrolled(false);
  //   }
  // }

  /* Aug 22, 2022 - remove fixed navbar with autoscroll in favor of simplicity */
  // useEffect( () => {
    
  //   window.addEventListener('scroll', onScroll);

  //   // removing event listener on component unmount
  //   return () => {
  //     window.removeEventListener('scroll', onScroll)
  //   }
  // }, []);

  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
      <Navbar expand="md" className="navbar fixed-top" dark>  
      {/* Aug 22, 2022 - remove fixed navbar with autoscroll in favor of simplicity 
      <Navbar expand="md" className={scrolled ? "navbar bg-opaque-dark fixed-top" : "navbar fixed-top"} dark> */}

        <NavbarBrand tag={RRNavlink} to='/' className="navbar-brand ml-md-4 mr-auto">
          <img src={Logo} width="423px" height="60px" alt="Inside Defense Logo" />
        </NavbarBrand>
        <NavbarToggler onClick={toggleNavbar}  />
          
        <Collapse isOpen={!collapsed} className="navbar-collapse mr-4" navbar>
          <Nav className="navbar-nav ml-auto pt-1" navbar>
            <NavItem className="px-2">
              <NavLink tag={RRNavlink} to='/start/contact-us'>Contact Us</NavLink>
            </NavItem>            
            <NavItem className="px-2">
              <NavLink tag={RRNavlink} to='/start/signin'>Enterprise Sign in</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    
  );
}

export default SiteNavbar;

