import React from 'react';
import { Card, CardHeader, CardBody, CardText } from 'reactstrap';
import { Link } from 'react-router-dom';

export const ActionCard = (props) => {

    return (
        <Card className="bg-container-navy text-center" style={{ height: '100%' }}>
            <CardHeader className="d-flex align-items-center justify-content-center pb-0">
                <i className={props.data.icon} aria-hidden="true"></i>
                <span style={{ fontSize: '1rem' }}>{props.data.header}</span>
            </CardHeader>
            <CardBody className="d-flex flex-column align-items-center justify-content-center">
                <CardText tag="h1"><strong>{props.data.value}</strong></CardText>
                <Link to={{ pathname: props.data.path, filter: props.data.filter }}>
                    <CardText className="smallText mb-1 text-secondary"><strong>{props.data.text}</strong></CardText>
                </Link>
            </CardBody>
        </Card>
    )
}