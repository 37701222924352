import React from 'react';
import { Container, Row, Col, Card, CardBody, Spinner, CardText, CardTitle } from 'reactstrap';
import { connect } from 'react-redux';
import { HomeCard } from './cards/HomeCard';
import Header from '../../../common/Header';
import PropTypes from 'prop-types';

let cardInfo = [
    { icon: 'fa fa-newspaper-o fa-2x text-secondary mr-2', header: "EXERCISES", text: 'Scenarios Completed', value: 0, valueText: '' },
    { icon: 'fa fa-shield fa-2x text-primary mr-2', header: "AWARENESS", text: 'Total Policy Awareness', value: 0, valueText: '%' },
    { icon: 'fa fa-line-chart fa-2x text-warning mr-2', header: "PARTICIPATION", text: 'Average Response Rate', value: 0, valueText: '%' },
];

const HomePage = (props) => {

    const { exercises, awareness, participation } = props;

    // number of exercises sent to the defender
    cardInfo[0].value = exercises;
    // percentage of correct answers
    cardInfo[1].value = awareness;
    // percentage of exercises completed
    cardInfo[2].value = participation;

    return (
        <React.Fragment>
            <Header title="Overview" icon="fa fa-home" />
            <Container fluid className='component-container home-container'>
                { exercises == 0 && 
                    <Row>
                        <Col xs="12" className="mb-4">
                            <Card className="bg-container-navy text-center">
                                <CardBody>
                                    <CardTitle tag="h2" className="text-center">Welcome</CardTitle>
                                    <CardText>The Inside Defense program will introduce you to the most effective cyberattacks used by hackers and criminals to target employees.</CardText>
                                    <CardText>We reference realworld attacks along with simple exercises to re-enforce your awareness of the risks and your cyberdefense skills.</CardText>
                                    <CardText>The policy list (link above) summarizes the recommended industry best practices to help prevent successful attacks against your company.</CardText>
                                    <CardText>In the coming days you'll receive new cybersecurity scenarios to your inbox as a reminder of the importance of cybersecurity skllls.</CardText>
                                    <CardText>Remember, hackers often target employees as the weak link in a company's defenses. So remain diligent and practice these simple skills every day.</CardText>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                }
                <Row>
                    {
                        cardInfo.map((data, i) =>
                            <Col key={i} xs="12" md="4" className="mb-4">
                                <HomeCard data={data} />
                            </Col>
                        )
                    }
                </Row>

            </Container>
        </React.Fragment>
    );
}

HomePage.propTypes = {
    exercises: PropTypes.number,
    awareness: PropTypes.number,
    participation: PropTypes.number
}

const mapStateToProps = state => ({
    exercises: state.defender.exercises,
    awareness: state.defender.awareness,
    participation: state.defender.participation
});

export default connect(mapStateToProps, null)(HomePage);