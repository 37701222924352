import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route 
        {...rest}
        render={props => {
            return (
                rest.token ? 
                        <Component {...props} /> : <Redirect to='/start/signin' />);
        }}
    />
);

PrivateRoute.propTypes = {
    token: PropTypes.string
}

const mapStateToProps = state => ({
    token: state.auth.token
})

export default connect(mapStateToProps, null)(PrivateRoute);



