import React from 'react';
import {
    Container, Row, Col, Table, Card, CardHeader, CardBody, CardFooter,
    Form, FormGroup, Input, Button, Badge, UncontrolledTooltip,
    Modal, ModalFooter, ModalBody
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { addDefenders, removeDefenders, remindDefenders } from '../../../state/actions/accountActions';
import { clearErrors } from '../../../state/actions/errorActions';
import Header from '../../common/Header';

class Defenders extends React.Component {

    state = {
        emails: [],
        value: '',
        error: null,
        modalOpen: false,
        reminderModalOpen: false,
        reminderButton: 'Send Reminder',
        emailId: null,
        selectedEmail: null,
        checkedList: []
    }

    componentDidUpdate(prevProps) {

        if (this.props.error !== prevProps.error) {
            if (this.props.error.id === 'ADD_DEFENDER_FAIL' ||
                this.props.error.id === 'REMOVE_DEFENDER_FAIL' ||
                this.props.error.id === 'REMIND_DEFENDER_FAIL') {
                this.setState({ error: this.props.error.msg.msg });
            }
            else {
                this.setState({ error: null });
            }
        }
        else if (this.props.isReminderSent !== prevProps.isReminderSent) {
            if (this.props.isReminderSent) {
                this.setState({ reminderButton: 'SENT' });
            }
        }
    }

    // Add Defenders Functions
    handleChange = event => {
        this.setState({
            value: event.target.value,
            error: null
        });
    }

    handleKeyDown = event => {
        if (['Enter', 'Tab', ' ', ','].includes(event.key)) {

            event.preventDefault();

            // remove whitespaces before and after 
            let email = this.state.value.trim();

            if (email && this.isValid(email)) {
                this.setState({
                    emails: [...this.state.emails, email],
                    value: ''
                });
            }
        }
    }

    handlePaste = event => {

        event.preventDefault();

        let paste = event.clipboardData.getData('text');

        let emails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

        if (emails) {
            let validEmails = emails.filter(email => !this.inList(email));

            this.setState({
                emails: [...this.state.emails, ...validEmails],
                value: ''
            });
        }
    }

    handleCancel = (event, removeEmail) => {

        event.preventDefault();

        const newList = this.state.emails.filter(email => email !== removeEmail);

        this.setState({ emails: newList });
    }

    isEmail = email => {
        return /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);
    }

    inList = email => {
        return this.state.emails.includes(email);
    }

    isValid = email => {
        let error = null;

        if (!this.isEmail(email)) {
            error = `${email} is not a valid email address`;
        }

        if (this.inList(email)) {
            error = `${email} is a duplicate`;
        }

        if (error) {
            this.setState({ error });
            return false;
        }

        return true;
    }

    onSubmit = event => {
        event.preventDefault();

        this.props.clearErrors();

        // update the list of defenders for the account
        this.props.addDefenders(this.state.emails);

        this.setState({
            emails: [],
            value: ''
        });
    };


    // Delete Defenders Functions
    handleListItemCheck = event => {

        let { checked, id } = event.target;

        if (checked) {
            // add to list
            this.setState({ checkedList: [...this.state.checkedList, id] });
        }
        else {
            // remove from list
            this.setState({ checkedList: this.state.checkedList.filter(listId => listId !== id) });
        }
    }

    handleDeleteDefenders = () => {
        this.props.clearErrors();

        this.toggleModal();

        // update the list of defenders for the account
        this.props.removeDefenders(this.state.checkedList);

        this.setState({ checkedList: [] });
    }

    handleRemindDefenders = () => {
        this.props.clearErrors();

        this.toggleReminderModal();

        this.setState({ reminderButton: 'Sending...' });

        // send reminder emails to the list of defenders
        this.props.remindDefenders(this.state.checkedList);

        this.setState({ checkedList: [] });
    }

    toggleModal = () => {
        if (this.state.checkedList.length > 0) {
            this.setState((state) => ({ modalOpen: !this.state.modalOpen }));
        }
    }

    toggleReminderModal = () => {
        if (this.state.checkedList.length > 0) {
            this.setState((state) => ({ reminderModalOpen: !this.state.reminderModalOpen }));
        }
    }

    render() {

        let defenderList = this.props.defenders;

        const { name } = this.props;
        const { emails, checkedList } = this.state;
        const { filter } = this.props.location;

        let message = `Welcome ${name}`;

        if (filter) {
            defenderList = defenderList.filter(defender => defender.status === filter)
        }

        return (
            <React.Fragment>
                <Header message={message} title="Manage Defenders" icon="fa fa-group" />

                <Container fluid className="component-container">
                    <Row>
                        <Col md="4" className="mb-4">
                            {!filter &&
                                <Card className="bg-container-navy">
                                    <CardHeader className="section-header pb-3">
                                        Add Defenders
                                    </CardHeader>

                                    <CardBody>
                                        <p className="smallText light">Type or paste emails, <strong>then click 'space'</strong></p>
                                        <Form onSubmit={this.onSubmit}>
                                            <FormGroup>
                                                <Input type="textarea"
                                                    className="content-text"
                                                    name="formInput"
                                                    placeholder="enter emails"
                                                    value={this.state.value}
                                                    onChange={this.handleChange}
                                                    onKeyDown={this.handleKeyDown}
                                                    onPaste={this.handlePaste}
                                                />
                                            </FormGroup>
                                            {this.state.error && <p className="errorText">{this.state.error}</p>}
                                            <FormGroup>
                                                {
                                                    emails.map((email, index) =>
                                                        <Badge className="mb-2 mr-1" key={index} href="#" color="light">
                                                            {email}
                                                            <Button onClick={(e) => this.handleCancel(e, email)} className="remove-btn" size="sm" color="light">&times;</Button>
                                                        </Badge>
                                                    )
                                                }
                                                {
                                                    emails.length ?
                                                        <React.Fragment>
                                                            <div id="btnAddDefenders" className="text-center">
                                                                <Button color="primary" outline>ADD</Button>
                                                            </div>
                                                            <UncontrolledTooltip placement="bottom" target="btnAddDefenders">
                                                                A confirmation email will be sent to each new defender
                                                            </UncontrolledTooltip>
                                                        </React.Fragment>
                                                        : null
                                                }
                                            </FormGroup>
                                        </Form>
                                    </CardBody>
                                </Card>
                            }
                            {
                                filter === "inactive" &&
                                <Card className="bg-container-navy">
                                    <CardHeader className="section-header border-0">
                                        Note
                                    </CardHeader>
                                    <CardBody>
                                        <p className="smallText">Participation is a prerequisite for improving cybersecurity awareness, and to reduce the risk of a successful cyber attack.</p>
                                        <p className="smallText">Select from the list to send an email reminder encouraging users to resume participation.</p>
                                    </CardBody>
                                </Card>
                            }
                            {
                                filter === "not verified" &&
                                <Card className="bg-container-navy">
                                    <CardBody>
                                        <p className="smallText">Review the list of unverified email addresses. Select and delete any invalid addresses found.</p>
                                    </CardBody>
                                </Card>
                            } 
                        </Col>

                        <Col md="8">
                            <Card className="bg-container-navy">
                                <CardBody className="py-0">
                                    <Table responsive className="mb-0">
                                        <thead className="section-header text-left">
                                            <tr>
                                                <th></th>
                                                <th>Defender</th>
                                                <th>Status</th>
                                                <th>Participation</th>
                                                {
                                                    (!filter || filter === "not verified") &&
                                                    <th>
                                                        <Button
                                                            size="sm"
                                                            color="primary"
                                                            outline onClick={this.toggleModal}
                                                        >
                                                            <i className="fa fa-trash-o"></i>
                                                        </Button>
                                                    </th>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody className="content-text">
                                            {
                                                defenderList.map((defender, index) => {
                                                    return (
                                                        <tr key={defender._id}>
                                                            <td>{index + 1}</td>
                                                            <td>{defender.email}</td>
                                                            <td>{defender.status}</td>
                                                            <td>{defender.responseRate} %</td>
                                                            <td>
                                                                <Input
                                                                    type="checkbox"
                                                                    className="listCheckbox"
                                                                    id={defender._id}
                                                                    onChange={this.handleListItemCheck}
                                                                />
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody>
                                    </Table>
                                </CardBody>
                                {
                                    filter === "inactive" &&
                                    <CardFooter className="border-0 text-center">
                                        <Button disabled={this.state.reminderButton !== 'Send Reminder'} size="sm" color="primary" outline onClick={this.toggleReminderModal}>{this.state.reminderButton}</Button>
                                        {this.state.error && <p className="errorText pt-2">{this.state.error}</p>}
                                    </CardFooter>
                                }
                            </Card>
                        </Col>
                    </Row>
                    <div>
                        <Modal isOpen={this.state.modalOpen} size="sm" centered>
                            <ModalBody className="bg-light">
                                <p className="text-center mt-2">
                                    <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>Confirm Delete?</span>
                                </p>
                                <p className="mb-0">You are about to permanently delete {checkedList.length} team member{checkedList.length > 1 ? 's' : ''}</p>
                            </ModalBody>
                            <ModalFooter className="bg-light">
                                <Button color="transparent" onClick={this.toggleModal}>Cancel</Button>
                                <Button className="ml-auto" color="primary" onClick={this.handleDeleteDefenders}>Confirm</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                    <div>
                        <Modal isOpen={this.state.reminderModalOpen} size="sm" centered>
                            <ModalBody className="bg-light">
                                <p className="text-center mt-2">
                                    <span style={{ fontSize: '1.1rem', fontWeight: '600' }}>Confirm Send</span>
                                </p>
                                <p className="mb-0">A reminder will be sent to {checkedList.length} team member{checkedList.length > 1 ? 's' : ''}</p>
                            </ModalBody>
                            <ModalFooter className="bg-light">
                                <Button color="transparent" onClick={this.toggleReminderModal}>Cancel</Button>
                                <Button className="ml-auto" color="primary" onClick={this.handleRemindDefenders}>Confirm</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                </Container>
            </React.Fragment >
        );
    }
}

Defenders.propTypes = {
    defenders: PropTypes.array,
    name: PropTypes.string,
    addDefenders: PropTypes.func.isRequired,
    removeDefenders: PropTypes.func.isRequired,
    remindDefenders: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    error: PropTypes.object.isRequired,
}

const mapStateToProps = state => ({
    defenders: state.account.data.defenders,
    name: state.account.data.name,
    isReminderSent: state.account.isReminderSent,
    error: state.error
});

export default connect(
    mapStateToProps,
    { addDefenders, removeDefenders, remindDefenders, clearErrors }
)(Defenders);
