import React from 'react';
import { Redirect } from 'react-router-dom';
import { Spinner, Container } from 'reactstrap';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import Proptypes from 'prop-types';
import { loadScenario } from '../../state/actions/scenarioActions';
import CommonNavbar from '../common/Navbar';
import Header from './common/Header';
import Footer from '../common/Footer';
import ReferenceInfo from './reference/ReferenceInfo';
import RiskInfo from './risk/RiskInfo';
import Question from './question/Question';
import Policy from './policy/Policy';
import Result from './result/Result';
import {
    SHOW_REFERENCE_INFO,
    SHOW_RISK_INFO,
    SHOW_QUESTION,
    SHOW_QUESTION_AGAIN,
    FIRST_ATTEMPT_CORRECT,
    FIRST_ATTEMPT_INCORRECT,
    SECOND_ATTEMPT_CORRECT,
    SECOND_ATTEMPT_INCORRECT
} from './globals.js';
import './scenario.css';

class Scenario extends React.Component {

    componentDidMount() {

        if (!this.props.scenarioIsLoaded) {

            let defenderToken = sessionStorage.getItem('defenderToken');

            if(!defenderToken) {
                // retrieve token from the email URL
                defenderToken = this.props.match.params.defenderToken;
                sessionStorage.setItem('defenderToken', defenderToken);
            }
                    
            this.props.loadScenario(defenderToken);
        }

    }

    render() {

        const { scenarioIsLoaded, organization, scenarioError, step, title } = this.props;

        if (!scenarioError.status && !scenarioIsLoaded) {
            return <Spinner color="primary">Loading...</Spinner>
        }

        // used for conditional rendering
        let activeComponent;
        // used as key for CSS transition
        let componentName;

        if (scenarioError.status) {
            // return <Redirect to="/error" />
            return <Redirect to={{ pathname: '/error', state: { 'msg': scenarioError.msg.msg } }} />
        }

        if (step === SHOW_REFERENCE_INFO) {
            componentName = 'ReferenceInfo';
            activeComponent = <ReferenceInfo />;
        }
        else if (step === SHOW_RISK_INFO) {
            componentName = 'RiskInfo';
            activeComponent = <RiskInfo />;
        }
        // else if (step === SHOW_QUESTION || step === FIRST_ATTEMPT_INCORRECT) {
        //     componentName = 'Question';
        //     activeComponent = <Question />;
        // }
        // replace the condition above with the two below
        else if (step === SHOW_QUESTION || step === SHOW_QUESTION_AGAIN) {
            componentName = 'Question';
            activeComponent = <Question />;
        }
        else if (step === FIRST_ATTEMPT_INCORRECT) {
            componentName = 'Policy';
            activeComponent = <Policy />;
        }
        else if (step === FIRST_ATTEMPT_CORRECT || step === SECOND_ATTEMPT_CORRECT) {
            componentName = 'Result';
            // componentName = 'Correct'
            activeComponent = <Result correct />;
        }
        else if (step === SECOND_ATTEMPT_INCORRECT) {
            componentName = 'Result';
            activeComponent = <Result incorrect />;
        }

        return (
            <div className="top-container-dark">
                <CommonNavbar organization={organization} />
                <Header title={title} center />

                <SwitchTransition>
                    <CSSTransition key={componentName} appear={true} timeout={1000} classNames="fade">
                        <section>
                            <Container className="scenario-container py-4">
                                {activeComponent}
                            </Container>
                        </section>
                    </CSSTransition>
                </SwitchTransition>
                <Footer />
            </div>
        );
    }
}

Scenario.propTypes = {
    scenarioIsLoaded: Proptypes.bool,
    organization: Proptypes.string,
    title: Proptypes.string,
    scenarioError: Proptypes.object,
    step: Proptypes.number,
    loadScenario: Proptypes.func.isRequired
};

const mapStateToProps = (state) => ({
    scenarioIsLoaded: state.scenario.scenarioIsLoaded,
    organization: state.scenario.organization,
    title: state.scenario.scenario.title,
    scenarioError: state.scenarioError,
    step: state.scenario.step,
    defenderToken: state.scenario.defenderToken
})

export default connect(mapStateToProps, { loadScenario })(Scenario);