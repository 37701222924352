import React from 'react';
import Chart from 'react-apexcharts';

class GaugeChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [props.a, props.r],
      options: {
        chart: {
          height: 390,
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            offsetY: -25,
            offsetX: 0,
            startAngle: 0,
            endAngle: 270,
            hollow: {
              margin: 5,
              size: '30%',
              background: 'transparent',
              image: undefined,
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                show: false,
              }
            }
          }
        },
        colors: ['#007bff', '#DF292D'],
        labels: ['Awareness', 'Participation'],
        legend: {
          show: true,
          floating: true,
          fontSize: '14px',
          position: 'left',
          offsetX: -32,
          offsetY: 0,
          labels: {
            useSeriesColors: false,
            colors: ['#ffffff']
          },
          markers: {
            size: 0
          },
          formatter: function (seriesName, opts) {
            return seriesName + ":  " + opts.w.globals.series[opts.seriesIndex] + "%"
          },
          itemMargin: {
            vertical: 8
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'top',
              offsetX: 0,
              offsetY: 0,
              floating: false
            }
          }
        }]
      },


    };
  }
  render() {
    return (
      <div id="chart">
        <Chart options={this.state.options} series={this.state.series} type="radialBar" height={350} />
      </div>
    );
  }
}

export default GaugeChart;