import React from 'react';
import {
    Container, Row, Col, Card, CardBody, CardHeader,
    ListGroup, ListGroupItem, ListGroupItemText, Alert,
    Modal, ModalBody, ModalFooter, Button, Form, FormGroup, Input, Label
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { updateProfile } from '../../../state/actions/accountActions';
import { pwReset, deleteAccount } from '../../../state/actions/authActions';
import { clearErrors } from '../../../state/actions/errorActions';

class Settings extends React.Component {

    state = {
        newName: this.props.name,
        newOrg: this.props.organization,
        profileModalOpen: false,
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: '',
        passwordModalOpen: false,
        clientErrMsg: '',
        serverErrMsg: '',
        isReset: false,
        deleteModalOpen: false
    }

    componentDidUpdate(prevProps, prevState) {

        const { error, pwWasReset, accountDeleted } = this.props;

        if (accountDeleted) {

            localStorage.removeItem('token');
            window.location.reload();
        }

        // check if error status has changed
        if (error !== prevProps.error) {
            if (error.id === 'RESET_FAIL' || error.id === 'ACCOUNT_DELETE_REQUEST_FAIL' || error.id === 'UPDATE_USER_FAIL') {
                this.setState({ serverErrMsg: error.msg.msg });
            }
            else {
                this.setState({ serverErrMsg: null });
            }
        }
        // check if password was just reset
        else if (pwWasReset && !prevProps.pwWasReset) {
            this.setState({ isReset: true })
        }
        // remove success message if already displayed to the user
        else if (this.state.isReset && prevState.isReset) {
            this.setState({ isReset: false })
        }
    }

    toggleProfileModal = () => {
        this.setState({ profileModalOpen: !this.state.profileModalOpen });

        if (this.state.isReset) {
            this.setState({ isReset: false });
        }
    }

    cancelProfileModal = () => {
        this.setState({
            newName: this.props.name,
            newOrg: this.props.organization
        })
        this.toggleProfileModal();
    }

    togglePasswordModal = () => {
        this.setState({ passwordModalOpen: !this.state.passwordModalOpen });
    }

    cancelPasswordModal = () => {
        this.setState({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        });
        this.togglePasswordModal();
    }

    toggleDeleteModal = () => {
        this.setState({ deleteModalOpen: !this.state.deleteModalOpen });
    }

    handleChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }

    handleSubmitProfile = event => {
        event.preventDefault();

        this.props.clearErrors();
        this.setState({
            clientErrMsg: '',
            serverErrMsg: '',
            isReset: false
        });

        this.toggleProfileModal();

        // call API to change the users profile information
        this.props.updateProfile({ name: this.state.newName, organization: this.state.newOrg });
    };

    handleSubmitPassword = event => {
        event.preventDefault();

        this.props.clearErrors();
        this.setState({
            clientErrMsg: '',
            serverErrMsg: '',
            isReset: false
        });

        const { newPassword, confirmNewPassword } = this.state;

        if (confirmNewPassword !== newPassword) {
            this.setState({ clientMsg: 'Passwords do not match', newPassword: '', confirmNewPassword: '' });
            return;
        }

        this.props.pwReset({ 'password': newPassword }, this.props.token);

        this.setState({
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        })
        this.togglePasswordModal();
    };

    handleDeleteAccount = event => {
        event.preventDefault();

        this.toggleDeleteModal();

        this.props.deleteAccount();
    }

    render() {

        const { name, email, organization, status } = this.props;

        return (
            <React.Fragment>
                <Container fluid className="component-container settings-container mt-4">
                    <Row>
                        <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 0 }} className="mb-2">
                            <Card className="mb-4 bg-container-navy">
                                <CardHeader className="d-flex justify-content-center align-items-center section-header">
                                    <div>
                                        Account Summary
                                    </div>
                                </CardHeader>

                                <CardBody className="p-0">
                                    <ListGroup flush id="settings-list">
                                        <ListGroupItem >
                                            <ListGroupItemText>Name: {name}</ListGroupItemText>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <ListGroupItemText>Organization: {organization}</ListGroupItemText>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <ListGroupItemText>Email: {email}</ListGroupItemText>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <ListGroupItemText>Account Status: {status}</ListGroupItemText>
                                        </ListGroupItem>
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 0 }} className="mb-2">
                            <Card className="mb-4 bg-container-navy">
                                <CardHeader className="d-flex justify-content-center align-items-center section-header">
                                    <div>
                                        Settings
                                    </div>
                                    {this.state.isReset && <Alert color="success">Password successfully changed</Alert>}
                                    {this.props.accountDeleted && <Alert color="success">Confirmation email has been sent</Alert>}
                                    {(this.props.error.id === 'RESET_FAIL' || this.props.error.id === "ACCOUNT_DELETE_REQUEST_FAIL") && <Alert color="danger">{this.state.serverMsg}</Alert>}
                                    {this.props.error.id === 'UPDATE_USER_FAIL' && <Alert color="danger">{this.state.serverMsg}</Alert>}
                                </CardHeader>

                                <CardBody className="p-0">
                                    <ListGroup flush id="settings-list">
                                        <ListGroupItem tag="button" onClick={this.toggleProfileModal} action>
                                            <ListGroupItemText>Edit Profile</ListGroupItemText>
                                        </ListGroupItem>
                                        <ListGroupItem tag="button" onClick={this.togglePasswordModal} action>
                                            <ListGroupItemText>Change Password</ListGroupItemText>
                                        </ListGroupItem>
                                        <ListGroupItem tag="button" onClick={this.toggleDeleteModal} action>
                                            <ListGroupItemText className="text-danger">Delete Account</ListGroupItemText>
                                        </ListGroupItem>
                                    </ListGroup>
                                </CardBody>
                            </Card>
                            {/* <Card className="mb-4 bg-container-navy">
                                <CardHeader className="section-header">
                                    Contact Support
                                </CardHeader>
                                <CardBody>
                                    <CardText>
                                        Please send your questions or feedback to: <a href="mailto:support@insidedefense.net" target="_blank" rel="noopener noreferrer">support@insidedefense.net</a>
                                    </CardText>
                                </CardBody>
                            </Card> */}
                        </Col>
                    </Row>
                    <Modal isOpen={this.state.profileModalOpen} centered>
                        <Form onSubmit={this.handleSubmitProfile}>
                            <ModalBody className="bg-light">
                                <p className="text-center mt-2"><span style={{ fontSize: '1.1rem', fontWeight: '600' }}>Edit Profile</span></p>
                                <FormGroup>
                                    <Label>Name: </Label>
                                    <Input type="text" name="newName" value={this.state.newName} onChange={this.handleChange} required />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Organization: </Label>
                                    <Input type="text" name="newOrg" value={this.state.newOrg} onChange={this.handleChange} required />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter className="bg-light">
                                <Button color="transparent" onClick={this.cancelProfileModal}>Cancel</Button>
                                <Button type="submit" className="ml-auto" color="primary">Save</Button>
                            </ModalFooter>
                        </Form>
                    </Modal>

                    <Modal isOpen={this.state.passwordModalOpen} centered>
                        <Form onSubmit={this.handleSubmitPassword}>
                            <ModalBody className="bg-light">
                                <p className="text-center mt-2"><span style={{ fontSize: '1.1rem', fontWeight: '600' }}>Change Password</span></p>
                                {this.state.clientMsg ? <Alert color="danger">{this.state.clientMsg}</Alert> : null}
                                <FormGroup>
                                    <Input type="password" name="oldPassword" placeholder='current password' bsSize='sm' onChange={this.handleChange} value={this.state.oldPassword} autoComplete='new-password' required minLength="8" maxLength="64" />
                                </FormGroup>
                                <FormGroup>
                                    <Input type="password" name="newPassword" placeholder='new password (min 8 chars)' bsSize='sm' onChange={this.handleChange} value={this.state.newPassword} autoComplete='new-password' required minLength="8" maxLength="64" />
                                    <div className="mt-2 text-center">
                                        <a style={{ fontSize: '0.875rem', color: '#555' }} href="https://1password.com/password-generator/" target="_blank" rel="noopener noreferrer">create a strong password</a>
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Input type="password" name="confirmNewPassword" placeholder='confirm new password' bsSize='sm' onChange={this.handleChange} value={this.state.confirmNewPassword} autoComplete='new-password' required minLength="8" maxLength="64" />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter className="bg-light">
                                <Button color="transparent" onClick={this.cancelPasswordModal}>Cancel</Button>
                                <Button type="submit" className="ml-auto" color="primary">Save</Button>
                            </ModalFooter>
                        </Form>
                    </Modal>

                    <Modal isOpen={this.state.deleteModalOpen} centered>
                        <ModalBody className="bg-light">
                            <p className="text-center mt-2"><span style={{ fontSize: '1.1rem', fontWeight: '600' }}>Delete Account?</span></p>
                            <p className="mb-0">Your account will be immediately deactivated.</p>
                            <p className="mb-0">A confirmation email will be sent to you before your account and all related data are permanently deleted.</p>
                        </ModalBody>
                        <ModalFooter className="bg-light">
                            <Button color="transparent" onClick={this.toggleDeleteModal}>Cancel</Button>
                            <Button className="ml-auto" color="danger" onClick={this.handleDeleteAccount}>Confirm</Button>
                        </ModalFooter>
                    </Modal>

                </Container>
            </React.Fragment>
        )
    }
}

Settings.propTypes = {
    name: PropTypes.string,
    email: PropTypes.string,
    organization: PropTypes.string,
    token: PropTypes.string,
    updateProfile: PropTypes.func.isRequired,
    deleteAccount: PropTypes.func.isRequired,
    pwReset: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    name: state.account.data.name,
    email: state.account.data.email,
    organization: state.account.data.organization,
    status: state.account.data.status,
    pwWasReset: state.auth.pwWasReset,
    accountDeleted: state.auth.accountDeleted,
    error: state.error,
    token: state.auth.token
})

export default connect(mapStateToProps, { updateProfile, deleteAccount, pwReset, clearErrors })(Settings);