import React from 'react';
import Chart from 'react-apexcharts';

class LineChart extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            options: {
                chart: {
                    id: "basic-bar",
                    toolbar: {
                        "show": false
                    }
                },
                colors: ['#dc3545'],
                theme: {
                    mode: 'dark'        
                },
                grid: {          
                    yaxis: {
                        lines: {
                            show: false
                        }
                    }          
                },
                yaxis: {
                    // opposite: true,
                    // title: {
                    //     text: 'Knowledge Gap %',
                    // },
                    tickAmount: 4
                    // labels: {
                    //     formatter: function(val) {
                    //       return val.toFixed(0);
                    //     }
                    //   }
                },
                xaxis: {
                    categories: [...this.props.dates],
                },
                stroke: {
                    curve: 'smooth',
                }
            },
            series: [
                {
                    name: "Knowledge Gap %",
                    data: [...this.props.trend]
                }
            ]
        };
    }

    render() {
        return (
            <Chart
                options={this.state.options}
                series={this.state.series}
                type="line"
                width="100%"
                height= "150px"
            />
        )
    }
}

export default LineChart;