import React from 'react';
import Chart from 'react-apexcharts';

class ColumnChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {

      series: [
        {
          name: 'Correct',
          data: [...this.props.firstResponders]
        },
        {
          name: 'Correction',
          data: [...this.props.progressives]
        },
        {
          name: 'Incorrect',
          data: [...this.props.vulnerable]
        }
      ],
      options: {
        colors: ['#007bff', '#28a745', '#dc3545'],        
        tooltip: {
          x: {
            show: true,
            formatter: (value, series) => {

              return this.props.titles[series.dataPointIndex];
            }
          }
        },
        yaxis: {
          title: {
            text: 'Scenario Results %',
          },
          tickAmount: 4
        },
        theme: {
          mode: 'dark'
        },
        grid: {
          yaxis: {
            lines: {
              show: false
            }
          }
        },

        chart: {
          type: 'bar',
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false                      
          },
          events: {
            dataPointSelection: (event, chartContext, config) => {
              const { dataPointIndex } = config;

              this.props.handleColumnClick(dataPointIndex);
            }
          }
        },
        xaxis: {
          categories: [...this.props.dates],
          tickPlacement: 'on'
        },

        fill: {
          opacity: .5
        },
        legend: {
          position: 'bottom',
          labels: {
            colors: 'white'
          }          
        },
        dataLabels: {
          hideOverflowingLabels: false

        }
      },
    };
  }

  render() {

    return (
      <div id="chart">
        <Chart options={this.state.options} series={this.state.series} type="bar" height={'250'} />
      </div>
    );
  }
}

export default ColumnChart;