import React from 'react';
import { Form, FormGroup, Input, Button, Alert } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { register, clearMsg } from '../../state/actions/authActions';
import { clearErrors } from '../../state/actions/errorActions';
import './start.css';

class Register extends React.Component {

    state = {
        name: '',
        email: '',
        password: '',
        confirmPassword: '',
        organization: '',
        msg: null,
        clientMsg: null
    };


    componentDidUpdate(prevProps) {

        const { error, newRegistration } = this.props;

        if (error !== prevProps.error) {
            if (error.id === 'REGISTER_FAIL') {
                this.setState({ msg: error.msg.msg });
            }
            else {
                this.setState({ msg: null });
            }
        }

        // registration successfully submitted
        else if(newRegistration !== prevProps.newRegistration) {
            this.setState({
                name: '',
                email: '',
                password: '',
                confirmPassword: '',
                organization: '',
            })
        }
    }

    onChange = e => {
        this.setState({ [e.target.name]: e.target.value });

        if(this.state.clientMsg) {
            this.setState({clientMsg: ''});
        }     
    };

    onSubmit = e => {
        e.preventDefault();

        this.props.clearErrors();
        this.props.clearMsg();

        const { name, email, password, confirmPassword, organization } = this.state;


        if (confirmPassword !== password) {
            this.setState({clientMsg: 'Passwords do not match'});
            return;
        }
        
        const newAccount = {
            name,
            email,
            password,
            organization
        };

        this.props.register(newAccount);
    };

    render() {
        
        return (
            <React.Fragment>
                <div className="form-container">
                    {this.state.msg ? <Alert color="danger">{this.state.msg}</Alert> : null}
                    {this.state.clientMsg ? <Alert color="danger">{this.state.clientMsg}</Alert> : null}
                    {this.props.isDataLoading ? <Alert color="secondary">Please wait a moment...</Alert> : null}
                    {this.props.newRegistration ? <Alert color="success">{this.props.successMsg}</Alert> : null}
                    <Form className="form" onSubmit={this.onSubmit} >
                        <h3>Welcome</h3>
                        <FormGroup>
                            <Input type="text" name="name" placeholder='full name' bsSize='sm' onChange={this.onChange} value={this.state.name} autoComplete="on" required />
                        </FormGroup>
                        <FormGroup>
                            <Input type="email" name="email" placeholder='email' bsSize='sm' onChange={this.onChange} value={this.state.email} autoComplete="username" required />
                        </FormGroup>
                        <FormGroup>
                            <Input type="text" name="organization" placeholder='organization' bsSize='sm' onChange={this.onChange} value={this.state.organization} autoComplete="on" required />
                        </FormGroup>
                        <FormGroup>
                            <Input type="password" name="password" placeholder='password (min 8 chars)' bsSize='sm' onChange={this.onChange} value={this.state.password} autoComplete='new-password' required minLength="8" maxLength="64" />
                            <div className="mt-2">
                                <a className="startLink" href="https://1password.com/password-generator/" target="_blank" rel="noopener noreferrer">create a strong password</a>
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Input type="password" name="confirmPassword" placeholder='confirm password' bsSize='sm' onChange={this.onChange} value={this.state.confirmPassword} autoComplete='new-password' required minLength="8" maxLength="64" />
                        </FormGroup>
                        { process.env.NODE_ENV !== 'production' && <Button className="form-button">JOIN</Button> }
                        { process.env.NODE_ENV === 'production'&& <Button disabled className="form-button">COMING SOON</Button> }

                        <div className="mt-2 startLinks">
                            <Link to='/start/signin' className="startLink">Already a member?</Link>
                        </div>
                    </Form>
                </div>
            </React.Fragment>
        );
    }
}

Register.propTypes = {
    isDataLoading: PropTypes.bool,
    newRegistration: PropTypes.bool,
    error: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired,
    clearMsg: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    isDataLoading: state.auth.isDataLoading,
    newRegistration: state.auth.newRegistration,
    error: state.error,
    successMsg: state.auth.msg,
});

export default connect(mapStateToProps, { register, clearErrors, clearMsg })(Register);
