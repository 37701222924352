import React from 'react';
import { Modal, ModalBody, ModalFooter, Button, DropdownItem } from 'reactstrap'

class Logout extends React.Component {

    state = {
        modalOpen: false,
        signOutConfirmed: false
    }

    toggleModal = () => {
        this.setState({ modalOpen: !this.state.modalOpen });
    }

    confirmLogout = e => {
        e.preventDefault();

        this.setState({ signOutConfirmed: true });

        this.toggleModal();
    };

    componentDidUpdate = (prevProps, prevState) => {

        if (prevState.modalOpen !== this.state.modalOpen) {
            if (this.state.signOutConfirmed) {

                localStorage.removeItem('token');

                window.location.reload();
            }
        }
    }

    render() {

        return (
            <React.Fragment>
                <DropdownItem onClick={this.toggleModal}>
                    <i className="fa fa-power-off" id="navIcon"></i>
                    Sign Out
                </DropdownItem>
                <Modal isOpen={this.state.modalOpen} size="sm" centered>
                    <ModalBody className="bg-light">
                        <p className="text-center mt-2"><span style={{ fontSize: '1.1rem', fontWeight: '600' }}>Sign out of Inside Defense?</span></p>
                        <p className="mb-0">You can sign in again anytime to monitor the teams progress.</p>
                    </ModalBody>
                    <ModalFooter className="bg-light">
                        <Button color="transparent" onClick={this.toggleModal}>Cancel</Button>
                         <Button className="ml-auto" color="primary" onClick={this.confirmLogout}>Sign Out</Button>
                    </ModalFooter>
                </Modal>
            </React.Fragment>
        );
    };
}

export default Logout;