import React from 'react';
import {
    Container, Row, Col, Card, CardHeader, CardBody
} from 'reactstrap';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import ColumnChart from './charts/ColumnChart';
import LineChart from './charts/LineChart';
import { InfoCard } from './cards/infoCard';
import Header from '../../common/Header';

let cardInfo = [
    { icon: 'fa fa-user-circle fa-2x text-danger mr-2', header: "Baseline Awareness", text: 'Policy and practices known', path: '/dashboard/defenders', filter: 'inactive', value: '', prepend: '', valueText: '%' },
    { icon: 'fa fa-graduation-cap fa-2x text-success mr-2', header: "Raised Awareness", text: 'Policy and practices learned', path: '/dashboard/defenders', filter: 'not verified', value: '', prepend: "+", valueText: '%' },
];

class Awareness extends React.Component {

    state = {
        redirect: false,
        targetId: null
    }

    // redirect to scenario report when user clicks chart datapoint
    handleColumnClick = chartIndex => {

        let targetId = this.props.statistics[chartIndex]._id;
        this.setState({ redirect: true, targetId: targetId })
    }

    render() {

        // navigate to the program page if the colum chart has been clicked
        if (this.state.redirect) {
            return (
                <Redirect to={{ pathname: '/dashboard/program', state: { 'selectedScenario': this.state.targetId } }} />
            )
        }

        const { name, statistics } = this.props;

        let message = `Welcome ${name}`;
        let numScenarios = statistics.length;
        let firstResponders = [], progressives = [], vulnerable = [], notParticipating = [], dates = [], titles = [], trend = [];
        let baseline = 0;
        let learned = 0;

        if (numScenarios > 0) {

            for (let i = 0; i < statistics.length; i++) {

                let formattedDate = new Date(statistics[i].sentDate).toLocaleDateString('en-us', { month: 'short', day: 'numeric' });
                dates.push(formattedDate);
                titles.push(statistics[i].scenario.title);
                firstResponders.push(statistics[i].numFirstCorrect);
                progressives.push(statistics[i].numSecondCorrect);
                vulnerable.push(statistics[i].numIncorrect);
                notParticipating.push(statistics[i].numUnverified + statistics[i].numSent - statistics[i].numReply);

                if (statistics[i].numReply > 0) {
                    baseline += (statistics[i].numFirstCorrect * 100) / statistics[i].numSent;
                    learned += (statistics[i].numSecondCorrect * 100) / statistics[i].numSent;
                        
                    trend.push(
                        Math.round(
                            statistics
                            .slice(0, i+1)
                            .reduce( (total, stat) => total + (stat.numIncorrect*100/stat.numReply),0)
                            /(i+1)
                        )
                    )
                }
            }

            cardInfo[0].value = Math.round(baseline / statistics.length);
            cardInfo[1].value = Math.round(learned / statistics.length);
        }

        return (
            <React.Fragment>
                <Header message={message} title="Cyber Awareness" icon="fa fa-shield" />

                <Container fluid className="component-container awareness-container">
                    <Row className="justify-content-center">
                        {
                            cardInfo.map((data, i) =>
                                <Col key={i} xs="12" md="6" lg="3" className="mb-4">
                                    <InfoCard data={data} />
                                </Col>
                            )
                        }
                        <Col xs="12" lg="6" className="mb-4">
                            <Card className="bg-container-navy">
                                <CardHeader className="d-flex align-items-center justify-content-center pb-0">
                                    <span style={{ fontSize: '1rem' }}>Vulnerability Trend</span>
                                </CardHeader>
                                <LineChart
                                    trend={trend}
                                    dates={dates}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs="12" className="mb-4 columnChart" >
                            <Card className="bg-container-navy">                                
                                <CardBody>
                                    <ColumnChart
                                        handleColumnClick={this.handleColumnClick}
                                        firstResponders={firstResponders}
                                        progressives={progressives}
                                        vulnerable={vulnerable}
                                        titles={titles}
                                        dates={dates} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </React.Fragment >
        );
    }
}

const mapStateToProps = state => ({
    name: state.account.data.name,
    statistics: state.account.data.statistics,
});
export default connect(mapStateToProps, null)(Awareness);