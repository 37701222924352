import React from 'react';
import {
    Container, Row, Col, Card, CardBody, CardHeader,
    ListGroup, ListGroupItem, ListGroupItemText, Alert,
    Modal, ModalBody, ModalFooter, Button, Form, FormGroup, Input, Label
} from 'reactstrap';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { deleteProfile } from '../../../../state/actions/defenderActions';
import { clearErrors } from '../../../../state/actions/errorActions';

class Settings extends React.Component {

    state = {
        profileModalOpen: false,
        clientErrMsg: '',
        serverErrMsg: '',
        deleteModalOpen: false
    }

    componentDidUpdate(prevProps, prevState) {

        const { error, profileDeleted } = this.props;

        if (profileDeleted) {

            localStorage.removeItem('defenderToken');
            window.location.reload();
        }

        // check if error status has changed
        if (error !== prevProps.error) {
            if ( error.id === 'PROFILE_DELETE_REQUEST_FAIL' ) {
                this.setState({ serverErrMsg: error.msg.msg });
            }
            else {
                this.setState({ serverErrMsg: null });
            }
        }
    }

    toggleDeleteModal = () => {
        this.setState({ deleteModalOpen: !this.state.deleteModalOpen });
    }

    handleDeleteProfile = event => {
        event.preventDefault();

        this.toggleDeleteModal();

        this.props.deleteProfile(sessionStorage.getItem('defenderToken'));
    }

    render() {

        const { email, organization } = this.props;

        return (
            <React.Fragment>
                <Container fluid className="component-container settings-container mt-4">
                    <Row>
                        <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 0 }} className="mb-2">
                            <Card className="mb-4 bg-container-navy">
                                <CardHeader className="d-flex justify-content-center align-items-center section-header">
                                    <div>
                                        Profile Summary
                                    </div>
                                </CardHeader>

                                <CardBody className="p-0">
                                    <ListGroup flush id="settings-list">
                                        <ListGroupItem>
                                            <ListGroupItemText>Organization: {organization}</ListGroupItemText>
                                        </ListGroupItem>
                                        <ListGroupItem>
                                            <ListGroupItemText>Email: {email}</ListGroupItemText>
                                        </ListGroupItem>
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col sm="12" md={{ size: 8, offset: 2 }} lg={{ size: 6, offset: 0 }} className="mb-2">
                            <Card className="mb-4 bg-container-navy">
                                <CardHeader className="d-flex justify-content-center align-items-center section-header">
                                    <div>
                                        Settings
                                    </div>
                                    {(this.props.error.id === "PROFILE_DELETE_REQUEST_FAIL") && <Alert color="danger">{this.state.serverErrMsg}</Alert>}
                                </CardHeader>

                                <CardBody className="p-0">
                                    <ListGroup flush id="settings-list">
                                        <ListGroupItem tag="button" onClick={this.toggleDeleteModal} action>
                                            <ListGroupItemText className="text-danger">Delete Account</ListGroupItemText>
                                        </ListGroupItem>
                                    </ListGroup>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Modal isOpen={this.state.deleteModalOpen} centered>
                        <ModalBody className="bg-light">
                            <p className="text-center mt-2"><span style={{ fontSize: '1.1rem', fontWeight: '600' }}>Delete Account?</span></p>
                            <p className="mb-0">Your profile will be immediately deactivated.</p>
                            <p className="mb-0">A confirmation email will be sent to you before your account is permanently deleted.</p>
                        </ModalBody>
                        <ModalFooter className="bg-light">
                            <Button color="transparent" onClick={this.toggleDeleteModal}>Cancel</Button>
                            <Button className="ml-auto" color="danger" onClick={this.handleDeleteProfile}>Confirm</Button>
                        </ModalFooter>
                    </Modal>
                </Container>
            </React.Fragment>
        )
    }
}

Settings.propTypes = {
    email: PropTypes.string,
    organization: PropTypes.string,
    token: PropTypes.string,
    deleteProfile: PropTypes.func.isRequired,
    clearErrors: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    email: state.defender.email,
    organization: state.defender.organization,
    profileDeleted: state.defender.profileDeleted,
    error: state.error,
    token: state.auth.token
})

export default connect(mapStateToProps, { deleteProfile, clearErrors })(Settings);