import {
    ACCOUNT_DELETE_REQUEST_SUCCESS,
    CLEAR_MSG,
    DATA_LOADING,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
    REFRESH_TOKEN,
    REGISTER_FAIL,
    REGISTER_SUCCESS,
    REQUEST_RESET_FAIL,
    REQUEST_RESET_SUCCESS,
    RESET_FAIL,
    RESET_SUCCESS,
    USER_LOADED,
    USER_LOADING_FAILED

} from "../actions/types";

const initialState = {
    token: localStorage.getItem('token'),
    newRegistration: null,
    isDataLoading: false,
    isAuthenticated: null,
    resetEmailSent: false,
    pwWasReset: false,
    accountDeleted: false
}

export default function authReducer(state = initialState, action) {

    switch (action.type) {

        case ACCOUNT_DELETE_REQUEST_SUCCESS:
            localStorage.removeItem('token');
            return {
                ...state,
                accountDeleted: true,
            };

        case CLEAR_MSG:
            return {
                ...state,
                msg: null,
                resetEmailSent: false,
                newRegistration: false
            };

        case DATA_LOADING:
            return {
                ...state,
                pwWasReset: false,
                isDataLoading: true
            };

        case LOGIN_FAIL:
            localStorage.removeItem('token');
            return {
                ...state,
                token: null,
                isAuthenticated: false,
                resetEmailSent: false,
                isDataLoading: false,
                newRegistration: false
            };

        case LOGIN_SUCCESS:
            localStorage.setItem('token', action.payload.token);
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true,
                resetEmailSent: false,
                isDataLoading: false
            };
        
        case REFRESH_TOKEN:
            localStorage.setItem('token', action.payload);
            return {
                ...state,
                token: action.payload,
                isAuthenticated: true,
            };

        case REGISTER_FAIL:
        case REQUEST_RESET_FAIL:
        case RESET_FAIL:
            return {
                ...state,
                resetEmailSent: false,
                isDataLoading: false
            };

        case REGISTER_SUCCESS:
            return {
                ...state,
                ...action.payload,
                newRegistration: true,
                isDataLoading: false
            };

        case REQUEST_RESET_SUCCESS:
            return {
                ...state,
                ...action.payload,
                resetEmailSent: true,
                isDataLoading: false
            };

        case RESET_SUCCESS:
            return {
                ...state,
                ...action.payload,
                resetEmailSent: false,
                pwWasReset: true,
                isDataLoading: false
            }

        case USER_LOADED:
            return {
                ...state,
                ...action.payload,
                isAuthenticated: true
            };

        case USER_LOADING_FAILED:
            return {
                ...state,
                isAuthenticated: false
            };

        default:
            return state;
    }
}