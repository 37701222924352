import {
    SCENARIO_LOADED,
    SCENARIO_LOADING_FAILED,
    SET_STEP,
    RESULT_SEND_FAILED,
    RESULT_SEND_SUCCESS
} from '../actions/types';

const initialState = {
    step: sessionStorage.getItem('scenarioStep') ? parseInt(sessionStorage.getItem('scenarioStep')) : 0,
    scenarioIsLoaded: false,
    scenario: {},
    organization: '',
    email: '',
    awarenessRate: 0,
    responseRate: 0,
    statsAreLoaded: false,
    error: null
}

export default function scenarioReducer(state = initialState, action) {

    switch (action.type) {
            
        case SCENARIO_LOADED:
            return {
                ...state,
                ...action.payload,
                scenarioIsLoaded: true
            };

        case SCENARIO_LOADING_FAILED:
            sessionStorage.removeItem('defenderToken');
            return {
                ...state
            };

        case SET_STEP:
            sessionStorage.setItem('scenarioStep', action.payload);
            return {
                ...state,
                step: action.payload
            };

        case RESULT_SEND_SUCCESS:
            sessionStorage.removeItem('scenarioStep');
            return {
                ...state,
                step: action.payload.nextStep,
                awarenessRate: action.payload.awarenessRate,
                responseRate: action.payload.responseRate,
                statsAreLoaded: true
            };
            
        case RESULT_SEND_FAILED:
            sessionStorage.removeItem('scenarioStep');
            return {
                ...state,
                step: action.payload,
            };
            
        default:
            return state;
    }
}