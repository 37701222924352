import {GET_SCENARIO_ERRORS, CLEAR_SCENARIO_ERRORS} from './types';

export const returnScenarioErrors = (msg, status, id = null) => {
    return {
        type: GET_SCENARIO_ERRORS,
        payload: { msg, status, id}
    };
}

export const clearScenarioErrors = () => {
    return {
        type: CLEAR_SCENARIO_ERRORS
    }
}