import React from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { Spinner, Card, Col, Row, CardTitle, Container, CardText } from 'reactstrap'

class UserConfirmation extends React.Component {

    state = {
        isConfirming: true,
        isResending: false,
        confirmed: false,
        expired: false,
        error: null,
        resendSuccess: false,
        resendFail: false,
        msg: null,
        email: ''
    }

    componentDidMount() {

        const { defenderToken } = this.props.match.params

        const config = {
            headers: {
                "Content-Type": "application/json",
                "x-auth-token": defenderToken
            }
        }

        axios.put('/api/defenders/confirmation', {}, config)
            .then(response => {
                this.setState({ isConfirming: false, confirmed: true });
            })
            .catch(err => {
                this.setState({ isConfirming: false, error: err.response.status, msg: err.response.data.msg });
            });
    }


    render() {
        const { isConfirming, confirmed, error } = this.state;


        if (isConfirming) {
            return <Spinner color="primary" />
        }

        else if (error) {
            return <Redirect to={{ pathname: '/error', state: { 'msg': this.state.msg } }} />
        }

        else if (confirmed) {
            return (
                <Container className="card-container">
                    <Row>
                        <Col>
                            <Card body>
                                <CardTitle tag="h2" className="text-center">Welcome</CardTitle>
                                <CardText>Criminals are targeting employees in order to attack and penetrate a company's private systems and data.</CardText>
                                <CardText>Inside Defense helps raise awareness of the risks and also the skills needed to defend against common cyberattacks.</CardText>
                                <CardText>In the next few days the program will send new cybersecurity scenarios to your inbox. You'll learn how criminals exploit our mistakes to launch attacks, as well as simple practices to bolster your own cyber defenses.</CardText>
                                <CardText className="font-weight-bold text-center">Together, let's build a strong defense to fend off cybercriminals.</CardText>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            )
        }
    }
}

export default UserConfirmation;