import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { authenticateDefender } from '../../../state/actions/defenderActions';

class DefenderAuthn extends React.Component {

    componentDidMount() {

        // retrieve token from the email URL
        let { defenderToken } = this.props.match.params;

        // verify authN token and retrieve authZ token for scenario Loading
        this.props.authenticateDefender(defenderToken);
    }

    render() {

        const { error, defenderIsAuthenticating } = this.props;

        if (error.status) {
            return <Redirect to={{ pathname: '/error', state: { 'msg': error.msg } }} />
        }

        if(defenderIsAuthenticating) {
            return null;
        }

        return <Redirect to='/defender/profile' />;
        
    }
}

const mapStateToProps = state => ({
    error: state.error,
    defenderIsAuthenticating: state.defender.defenderIsAuthenticating
})

DefenderAuthn.propTypes = {
    error: PropTypes.object,
    authenticateDefender: PropTypes.func.isRequired,
    defenderIsAuthenticating: PropTypes.bool
}

export default connect(mapStateToProps, { authenticateDefender })(DefenderAuthn);