import axios from 'axios';
import { 
    ACCOUNT_LOADED,    
    ACCOUNT_LOADING_FAILED, 
    ADD_DEFENDER_FAIL,
    ADD_DEFENDER_SUCCESS,    
    LOAD_SCENARIO_FAIL,
    LOAD_SCENARIO_SUCCESS,
    LOAD_POLICIES_FAIL,
    LOAD_POLICIES_SUCCESS,
    REFRESH_TOKEN,
    REMIND_DEFENDER_FAIL,
    REMIND_DEFENDER_SUCCESS,   
    REMOVE_DEFENDER_FAIL,
    REMOVE_DEFENDER_SUCCESS,
    UPDATE_USER_FAIL,
    UPDATE_USER_SUCCESS
} from '../actions/types';
import { returnErrors } from './errorActions';


// Action: loadAccount
// Desc: get the users account information
export const loadAccount = () => (dispatch, getState) => {

    // call API to retrieve account data
    axios.get('/api/accounts/data', tokenConfig(getState))
    .then(response => {
        dispatch( { type: ACCOUNT_LOADED, payload: response.data.data  });
        dispatch( { type: REFRESH_TOKEN, payload: response.data.token});
    })
    .catch(err => {

        if(err.response) {
            if (err.response.data.msg === 'Token expired') {
                dispatch( returnErrors(err.response.data, err.response.status, 'TOKEN_EXPIRED'));
            }
            else {
                dispatch( returnErrors(err.response.data, err.response.status, ACCOUNT_LOADING_FAILED));
            }            
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, ACCOUNT_LOADING_FAILED));
        }
       
        dispatch( {
            type: ACCOUNT_LOADING_FAILED
        });
    });
}

// Action:  updateProfile
// Desc:    modify profile settings
export const updateProfile = profile => (dispatch, getState) => {   
        
    const body = JSON.stringify( profile );

    // call API to add defenders to the account
    axios.put('/api/accounts/user', body, tokenConfig(getState))
    .then(res => {
        dispatch({ type: UPDATE_USER_SUCCESS, payload: res.data });
        dispatch({ type: REFRESH_TOKEN, payload: res.data.token});
    })
    .catch(err => {
        if(err.response) {
            if (err.response.data.msg === 'Token expired') {
                dispatch( returnErrors(err.response.data, err.response.status, 'TOKEN_EXPIRED'));
            }
            else {
                dispatch( returnErrors(err.response.data, err.response.status, UPDATE_USER_FAIL));
            }
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, UPDATE_USER_FAIL));
        }
        
        dispatch( {
            type: UPDATE_USER_FAIL    
        })
    })
}

// Action: loadPolicies
// Desc: get the cyber security policies from the server
export const loadPolicies = () => (dispatch, getState) => {
    axios.get('/api/policies', tokenConfig(getState))
    .then(res => {
        dispatch( { type: LOAD_POLICIES_SUCCESS, payload: res.data});
        //dispatch( { type: REFRESH_TOKEN, payload: res.data.token});
    })
    .catch(err => {
        if(err.response) {
            if (err.response.data.msg === 'Token expired') {
                dispatch( returnErrors(err.response.data, err.response.status, 'TOKEN_EXPIRED'));
            }
            else {
                dispatch( returnErrors(err.response.data, err.response.status, LOAD_POLICIES_FAIL));
            }
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, LOAD_POLICIES_FAIL));
        }

        dispatch( {
            type: LOAD_POLICIES_FAIL    
        })
    });
}

// Action: loadScenario
// Desc:   get a scenario information from the server
export const loadScenario = (scenarioId) => (dispatch, getState) => {
    
    axios.get(`/api/accounts/scenario/${scenarioId}`, tokenConfig(getState))
    .then(res => {
            dispatch( { type: LOAD_SCENARIO_SUCCESS, payload: res.data.scenario});
            dispatch( { type: REFRESH_TOKEN, payload: res.data.token});
        }
    )
    .catch(err => {
        if(err.response) {
            if (err.response.data.msg === 'Token expired') {
                dispatch( returnErrors(err.response.data, err.response.status, 'TOKEN_EXPIRED'));
            }
            else {
                dispatch( returnErrors(err.response.data, err.response.status, LOAD_SCENARIO_FAIL));
            }
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, LOAD_SCENARIO_FAIL));
        }

        dispatch( {
            type: LOAD_SCENARIO_FAIL    
        })
    });
}

// Action:  addDefenders
// Desc:    add new defenders to the account
export const addDefenders = defenderList => (dispatch, getState) => {   
        
    const body = JSON.stringify( defenderList );

    // call API to add defenders to the account
    axios.post('/api/accounts/defenders', body, tokenConfig(getState))
    .then(res => {
        dispatch({ type: ADD_DEFENDER_SUCCESS, payload: res.data.defenders });
        dispatch({ type: REFRESH_TOKEN, payload: res.data.token});
    })
    .catch(err => {
        if(err.response) {
            if (err.response.data.msg === 'Token expired') {
                dispatch( returnErrors(err.response.data, err.response.status, 'TOKEN_EXPIRED'));
            }
            else {
                dispatch( returnErrors(err.response.data, err.response.status, ADD_DEFENDER_FAIL));
            }
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, ADD_DEFENDER_FAIL));
        }
        
        dispatch( {
            type: ADD_DEFENDER_FAIL    
        })
    })
}

// Action:  removeDefenders
// Desc:    remove defenders from the account
export const removeDefenders = ( defenderIdList ) => (dispatch, getState) => {   

    const config = {
        headers: { "Content-Type": "application/json" },
        data: JSON.stringify( defenderIdList )
    }

    const token = getState().auth.token;

    if(token) {
        config.headers['x-auth-token'] = token;
    }
    
    // call API to remove defenders from the account
    axios.delete('/api/accounts/defenders', config)
    .then(res => {
        dispatch( { type: REMOVE_DEFENDER_SUCCESS, payload: res.data.defenders });
        dispatch( { type: REFRESH_TOKEN, payload: res.data.token })
    })
    .catch(err => {
        if(err.response) {
            if (err.response.data.msg === 'Token expired') {
                dispatch( returnErrors(err.response.data, err.response.status, 'TOKEN_EXPIRED'));
            }
            else {
                dispatch( returnErrors(err.response.data, err.response.status, REMOVE_DEFENDER_FAIL));
            }
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, REMOVE_DEFENDER_FAIL));
        }
        
        dispatch( {
            type: REMOVE_DEFENDER_FAIL,            
        })
    })
}

// Action:  remindDefenders
// Desc:    send email reminder to inactive Defenders
export const remindDefenders = ( defenderIdList ) => (dispatch, getState) => {   

    const body = JSON.stringify( defenderIdList );

    // call API to add defenders to the account
    axios.post('/api/accounts/defenders/reminder', body, tokenConfig(getState))
    .then(res => dispatch( {
        type: REMIND_DEFENDER_SUCCESS
    }))
    .catch(err => {
        if(err.response) {
            if (err.response.data.msg === 'Token expired') {
                dispatch( returnErrors(err.response.data, err.response.status, 'TOKEN_EXPIRED'));
            }
            else {
                dispatch( returnErrors(err.response.data, err.response.status, REMIND_DEFENDER_FAIL));
            }
        }
        else if(err.request) { //no response received
            dispatch( returnErrors({msg: "Internal Error. Please try again later."}, 500, REMIND_DEFENDER_FAIL));
        }
        
        dispatch( {
            type: REMIND_DEFENDER_FAIL,            
        })
    })
}


// Function:  tokenConfig
// Desc:    retrieve token from state and add to config header
export const tokenConfig = getState => {
    
    const token = getState().auth.token;

    const config = {
        headers: {
            "Content-Type": "application/json"
        }
    }

    if(token) {
        config.headers['x-auth-token'] = token;
    }

    return config;
}