import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import SiteNavbar from './Navbar';
import UserRegistration from './UserRegistration';
import Footer from '../common/Footer';
import './landing.css';


const Landing = () => {

    return (
        <div className="top-container">
            <nav><SiteNavbar /></nav>
            <img src='/site-img.jpg' alt='site background' height="1080px" width="1920px" style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
            <div className="bg-img-overlay"></div>
            <div className="content-container landing-content">
                <Container fluid className="ml-md-4 light">
                    <Row>
                        <Col md="12" lg="10" xl="8">
                            <h1>Workforce Security Awareness</h1>
                            <hr align="left" className="divider my-3" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12" lg="10" xl="8">
                            <p className="lead">Promote cyber-safe work practices to defend your company's resources from cyber attacks.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="10" className="pt-2">
                            <UserRegistration />
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer className="mt-3" />
        </div>
    )
}

export default Landing;