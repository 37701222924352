import React from 'react';

const Header = (props) => {

    return (
        <React.Fragment>
            {
                props.center &&

                <header className="common-header justify-content-center">
                    <div id="header-center">
                        <span className="ml-2">{props.title} Security</span>
                    </div>
                </header>
            }
            {
                !props.center &&
            
                <header className="common-header justify-content-between">
                    <div id="header-left">
                        <span className={props.icon} aria-hidden="true"></span>
                        {/* <span className="ml-2">{props.title}</span> */}
                        <h4>{props.title} Security</h4>
                    </div>
                    {
                        props.message &&

                        <div id="header-right">
                            {props.message}
                        </div>
                    }
                </header>
            }

        </React.Fragment>


    );
}

export default Header;
