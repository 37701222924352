import React from 'react';
import { Nav, Navbar, NavbarBrand, NavItem, NavLink } from 'reactstrap';
import { NavLink as RRNavlink } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from '../../assets/images/logo_423x60.png';

const CommonNavbar = (props) => {

  const { organization, bgColor } = props;

  return (
    <Navbar className={bgColor === 'dark' ? "common-navbar bg-opaque-dark" : "common-navbar"}>
      <NavbarBrand className="navbar-brand mr-auto">
        <img src={Logo} width="423px" height="60px" alt="Inside Defense Logo" />
        <span className="hide-on-small-screen" id="company-brand-text">{organization ? `@ ${organization}` : ""}</span>
      </NavbarBrand>
      <Nav className="navbar-nav ml-auto mr-4" navbar>
        <NavItem className="px-2">
          <NavLink target="_blank" tag={RRNavlink} to='/start/contact-us'>Contact Us</NavLink>
        </NavItem>
      </Nav>
    </Navbar>
  );
}

CommonNavbar.propTypes = {
  organization: PropTypes.string
}

export default CommonNavbar;

